import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const TikTokComments = () => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const socket = io('https://backend-tok.weblab99.com');

    socket.on('chat', (data) => {
      setComments((prevComments) => [...prevComments, data]);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <h1>TikTok Live Comments</h1>
      <ul>
        {comments.map((comment, index) => (
          <li key={index}>
            <img src={comment.profilePictureUrl} alt={`${comment.nickname}'s profile`} width="50" height="50" />
            <p><strong>{comment.nickname}</strong>: {comment.comment}</p>
            <p>{comment.followRole ? "Follower" : "Non-follower"}</p>
            <p>{new Date().toLocaleString()}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TikTokComments;
