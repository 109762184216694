import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const SettingsUpdate = () => {
  const { lng, userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(15);
  const [timer, setTimer] = useState(40);
  const [hashtag, setHashtag] = useState('');
  const [level, setLevel] = useState(lng === 'en' ? 'secondlevelUp' : 'secondlevelUp');
  const [questionCounts, setQuestionCounts] = useState({ firstlevelUp: 0, secondlevelUp: 0, thirdlevelUp: 0, alllevels: 0 });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAutoMode, setIsAutoMode] = useState(false);


  useEffect(() => {
    setHashtag(t('hashtag_placeholder'));
  }, [t]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`https://backend-tok.weblab99.com/users/${userId}`);
        setUserData(res.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const levels = ['firstlevelUp', 'secondlevelUp', 'thirdlevelUp'];
    const counts = {};

    levels.forEach((level) => {
      const data = JSON.parse(localStorage.getItem(level) || '[]');
      counts[level] = Object.keys(data).length; // Count the number of keys (questions)
      // console.log(`Level: ${level}, Data:`, data);
    });

    counts.alllevels = Object.keys(counts).reduce((sum, level) => sum + counts[level], 0);

    // console.log('Counts:', counts);
    setQuestionCounts(counts);
  }, []);

  // Moved storeJSONInLocalStorage function here
  useEffect(() => {
    const storeJSONInLocalStorage = async () => {
      try {
        const levels = ['firstlevelUp', 'secondlevelUp', 'thirdlevelUp'];
        const counts = {};

        for (const level of levels) {
          let existingData = localStorage.getItem(level);
          let needsRepopulate = false;

          if (existingData) {
            const parsedData = JSON.parse(existingData);

            if (Array.isArray(parsedData)) {
              const convertedData = parsedData.reduce((acc, question) => {
                acc[question.id] = question;
                return acc;
              }, {});
              localStorage.setItem(level, JSON.stringify(convertedData));
            } else if (Object.keys(parsedData).length <= 25) {
              needsRepopulate = true;
            }
          } else {
            needsRepopulate = true;
          }

          if (needsRepopulate) {
            const response = await axios.get(`/questionsArUp/${level}.json`);
            const data = response.data.reduce((acc, question) => {
              acc[question.id] = question;
              return acc;
            }, {});
            localStorage.setItem(level, JSON.stringify(data));
          }

          // Calculate count for this level
          const storedData = JSON.parse(localStorage.getItem(level) || '{}');
          counts[level] = Object.keys(storedData).length;
        }

        // Calculate total count
        counts.alllevels = Object.values(counts).reduce((sum, count) => sum + count, 0);

        // Update the state with the new counts
        setQuestionCounts(counts);
      } catch (error) {
        console.error('Error fetching or storing JSON files:', error);
      }
    };

    const storageKey = `quiz_${userId}`;
    // Empty the storage key but not delete it
    localStorage.setItem(storageKey, '');
    storeJSONInLocalStorage();
  }, [userId]);


  const handleStartGame = () => {
    if (totalQuestions <= 0 || isNaN(totalQuestions)) {
      alert(t('invalid_questions'));
      return;
    }
    if (timer <= 0 || isNaN(timer)) {
      alert(t('invalid_timer'));
      return;
    }

    navigate(`/${lng}/${userId}/brainchallengeupdate/questionupdate?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestionIndex=0&timer=${timer}&level=${level}&isAutoMode=${isAutoMode}`);
  };

  if (!userData) return <p>{t('loading')}...</p>;

  return (
    <div className="container mt-1">
      <div className="bgTransparent rounded-5 px-3 py-2 mt-2">
        <h2 className="mb-4 fontcolorYellow fw-bold">{t('game_settings')}</h2>
        <div className="mb-3 row align-items-between">
          <label htmlFor="hashtag" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('hashtag')}</label>
          <input
            type="text"
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="hashtag"
            value={hashtag}
            onChange={e => setHashtag(e.target.value)}
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="totalQuestions" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('total_questions')}</label>
          <input
            type="number"
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="totalQuestions"
            value={totalQuestions}
            onChange={e => setTotalQuestions(parseInt(e.target.value))}
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="timer" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('question_timer')}</label>
          <input
            type="number"
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="timer"
            value={timer}
            min="1"
            onChange={e => setTimer(parseInt(e.target.value))}
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="level" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('level')}</label>
          <select
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="level"
            value={level}
            onChange={e => setLevel(e.target.value)}
          >
            <option className='fontcolorPrimary fw-semibold' value="firstlevelUp">
              {t('first_level')} ({questionCounts.firstlevelUp} {t('questions')})
            </option>
            <option className='fontcolorPrimary fw-semibold' value="secondlevelUp">
              {t('second_level')} ({questionCounts.secondlevelUp} {t('questions')})
            </option>
            <option className='fontcolorPrimary fw-semibold' value="thirdlevelUp">
              {t('third_level')} ({questionCounts.thirdlevelUp} {t('questions')})
            </option>
            <option className='fontcolorPrimary fw-semibold' value="alllevels">
              {t('all_levels')} ({questionCounts.alllevels} {t('questions')})
            </option>
          </select>
        </div>
        <div className="mb-3 text-end">
          <label htmlFor="autoMode" className="form-label col-5 col-sm-5 align-middle fontcolorWhite text-center">{t('auto_mode')}</label>
          <input
            type="checkbox"
            id="autoMode"
            checked={isAutoMode}
            onChange={(e) => setIsAutoMode(e.target.checked)}
          />
        </div>
        <div className="row d-flex justify-content-center" style={{ margin: '1em auto' }}>
          <div className="str col-12 d-flex justify-content-center mt-3">
            <button onClick={handleStartGame} style={{ width: '70px', height: '70px' }} id="start" className="fancy button">
              <h4 className="m-0 fontcolorYellow" style={{ position: 'relative', zIndex: '1' }}>{t('start')}</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsUpdate;
