import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
// Remove the useLocation import if it's not used
// import { useLocation } from 'react-router-dom';

const SoundContext = createContext();

export const useSound = () => useContext(SoundContext);

export const SoundProvider = ({ children }) => {
  const [volume, setVolume] = useState(0); // default volume
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  // const location = useLocation(); // Remove this line if it's not used

  const [backgroundAudio] = useState(new Audio('/sounds/million.mp3'));
  const [backgroundAudio2] = useState(new Audio('/sounds/alex.mp3'));
  const [backgroundAudio3] = useState(new Audio('/sounds/adventure.mp3'));
  const [backgroundAudio4] = useState(new Audio('/sounds/cooking.mp3'));
  const [backgroundAudio5] = useState(new Audio('/sounds/monkeys.mp3'));
  const [backgroundAudio6] = useState(new Audio('/sounds/wallpaper.mp3'));
  const [cheerAudio] = useState(new Audio('/sounds/cheering.mp3'));
  const [timerAudio] = useState(new Audio('/sounds/timer.MP3'));
  const [swingAudio] = useState(new Audio('/sounds/swing.mp3'));
  const [likeAudio] = useState(new Audio('/sounds/likes.wav'));
  const [followAudio] = useState(new Audio('/sounds/follow.wav'));
  const [giftAudio] = useState(new Audio('/sounds/gift.mp3'));

  useEffect(() => {
    backgroundAudio.loop = true;
    backgroundAudio2.loop = true;
    backgroundAudio3.loop = true;
    backgroundAudio4.loop = true;
    backgroundAudio5.loop = true;
    backgroundAudio6.loop = true;
  }, [backgroundAudio, backgroundAudio2, backgroundAudio3, backgroundAudio4, backgroundAudio5, backgroundAudio6]);

  useEffect(() => {
    backgroundAudio.volume = volume / 100;
    backgroundAudio2.volume = volume / 400;
    backgroundAudio3.volume = volume / 100;
    backgroundAudio4.volume = volume / 100;
    backgroundAudio5.volume = volume / 100;
    backgroundAudio6.volume = volume / 100;
    cheerAudio.volume = volume / 400;
    timerAudio.volume = volume / 100;
    swingAudio.volume = volume / 100;
    likeAudio.volume = volume / 400;
    followAudio.volume = volume / 100;
    giftAudio.volume = volume / 100;
  }, [volume, backgroundAudio, backgroundAudio2, backgroundAudio3, backgroundAudio4, backgroundAudio5, backgroundAudio6, cheerAudio, timerAudio, swingAudio, likeAudio, followAudio, giftAudio]);

  const handleUserInteraction = useCallback(() => {
    setIsUserInteracted(true);
    document.removeEventListener('click', handleUserInteraction);
    document.removeEventListener('touchstart', handleUserInteraction);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };
  }, [handleUserInteraction]);

  const playCheer = () => isUserInteracted && cheerAudio.play();
  const pauseCheer = () => isUserInteracted && cheerAudio.pause();

  const playTimer = () => isUserInteracted && timerAudio.play();
  const pauseTimer = () => isUserInteracted && timerAudio.pause();

  const playSwing = () => isUserInteracted && swingAudio.play();
  const pauseSwing = () => isUserInteracted && swingAudio.pause();

  const playLike = () => isUserInteracted && likeAudio.play();
  const pauseLike = () => isUserInteracted && likeAudio.pause();

  const playFollow = () => isUserInteracted && followAudio.play();
  const pauseFollow = () => isUserInteracted && followAudio.pause();

  const playGift = () => isUserInteracted && giftAudio.play();
  const pauseGift = () => isUserInteracted && giftAudio.pause();

  // Move pauseBackground above playBackground
  const pauseBackground = useCallback(() => {
    if (isUserInteracted) {
      backgroundAudio.pause();
      backgroundAudio2.pause();
      backgroundAudio3.pause();
      backgroundAudio4.pause();
      backgroundAudio5.pause();
      backgroundAudio6.pause();
    }
  }, [isUserInteracted, backgroundAudio, backgroundAudio2, backgroundAudio3, backgroundAudio4, backgroundAudio5, backgroundAudio6]);

  const playBackground = useCallback(
    (audioKey = 'backgroundAudio') => {
      if (isUserInteracted) {
        pauseBackground(); // Pause all audios first
        switch (audioKey) {
          case 'backgroundAudio':
            backgroundAudio.play();
            break;
          case 'backgroundAudio2':
            backgroundAudio2.play();
            break;
          case 'backgroundAudio3':
            backgroundAudio3.play();
            break;
          case 'backgroundAudio4':
            backgroundAudio4.play();
            break;
          case 'backgroundAudio5':
            backgroundAudio5.play();
            break;
          case 'backgroundAudio6':
            backgroundAudio6.play();
            break;
          default:
            backgroundAudio.play();
        }
      }
    },
    [isUserInteracted, backgroundAudio, backgroundAudio2, backgroundAudio3, backgroundAudio4, backgroundAudio5, backgroundAudio6, pauseBackground]
  );
  
  return (
    <SoundContext.Provider value={{ volume, setVolume, playCheer, pauseCheer, playTimer, pauseTimer, playSwing, pauseSwing, playLike, pauseLike, playFollow, pauseFollow, playGift, pauseGift, playBackground, pauseBackground }}>
      {children}
    </SoundContext.Provider>
  );
};
