
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Carousel } from 'react-bootstrap';
import 'animate.css/animate.min.css';
import './LandingPage.scss';
import { useTranslation } from 'react-i18next';

const LandingPage = () => {
  const [activeSection, setActiveSection] = useState('section1');
  const sectionRefs = useRef([]);
  const scrolling = useRef(false);
  const [animateSection, setAnimateSection] = useState(activeSection); // Initialize with activeSection
  const { t } = useTranslation();
  const sections = useMemo(() => ['section1', 'section2', 'section3', 'section4'], []);


  useEffect(() => {
    const handleScroll = (event) => {
      if (scrolling.current) return;

      scrolling.current = true;
      const delta = event.deltaY;
      let newActiveSection = activeSection;

      if (delta > 0) {
        const currentIndex = sections.indexOf(activeSection);
        newActiveSection = sections[Math.min(currentIndex + 1, sections.length - 1)];
      } else {
        const currentIndex = sections.indexOf(activeSection);
        newActiveSection = sections[Math.max(currentIndex - 1, 0)];
      }

      scrollToSection(newActiveSection);
      setAnimateSection(newActiveSection); // Trigger animation for new active section
      setTimeout(() => {
        scrolling.current = false;
      }, 1000); // match the CSS transition duration
    };

    window.addEventListener('wheel', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [activeSection, sections]);

  useEffect(() => {
    // Trigger animation for initial active section on page load
    setAnimateSection(activeSection);
  }, [activeSection]);

  const scrollToSection = (section) => {
    setActiveSection(section);
  };

  const navIcons = [
    "https://cdn.lordicon.com/qeltvbrs.json",
    "https://cdn.lordicon.com/bhmovrlt.json",
    "https://cdn.lordicon.com/nlmjynuq.json",
    "https://cdn.lordicon.com/njdhzovn.json"
  ];

  const [iconSize, setIconSize] = useState({ width: '50px', height: '50px' });
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 450) {
        setIconSize({ width: '32px', height: '32px' });
      } else {
        setIconSize({ width: '50px', height: '50px' });
      }
    };

    handleResize(); // Call initially to set the size based on the current window size
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderNavIcon = (section, index) => (
    <div
      key={section}
      className={`my-2 nav-icon ${activeSection === section ? 'active' : ''}`}
      onClick={() => scrollToSection(section)}
    >
      <lord-icon
        src={navIcons[index]}
        trigger="hover"
        state="hover-draw"
        style={iconSize}>
      </lord-icon>
    </div>
  );

  const renderSectionContent = (section) => {
    const isActive = section === activeSection;

    return (
      <div className={`container mt-5 animate__animated ${isActive && animateSection === section ? 'animate__zoomInDown' : ''}`}>
        {/* Your content for each section */}
        {/* Example content for section 1 */}
        {section === 'section1' && (
          <div className="container">
            <div className="row align-items-center">
              <div className={`col-12 col-md-6 container animate__animated ${isActive && animateSection === section ? 'animate__lightSpeedInRight animate__delay-1s' : ''}`}>
                <div>
                  <h1>
                    {t('interactive_games')}
                  </h1>
                  <h4 className="text-align-start my-3">
                    {t('step1')} <br />
                    {t('step2')} <br />
                    {t('step3')} <br />
                    {t('step4')} <br />
                    {t('step5')} <br />
                  </h4>
                </div>
              </div>
              <div className={`col-12 col-md-6 container animate__animated ${isActive && animateSection === section ? 'animate__lightSpeedInLeft animate__delay-1s' : ''}`}>
                <Carousel interval={2000} wrap={true}>
                  <Carousel.Item interval={3000}>
                    <img
                      className="d-block w-75 mb-5 mx-auto custom-fade"
                      src="./images/img1.png"
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item interval={3000}>
                    <img
                      className="d-block w-75 mb-5 mx-auto custom-fade"
                      src="./images/img2.png"
                      alt="Second slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        )}
        {/* Example content for section 2 */}
        {section === 'section2' && (
          <div className="container">
            <div className="row align-items-center">
              <div className={`mt-5 col-12 col-md-6 container mt-5 animate__animated ${isActive && animateSection === section ? 'animate__zoomInDown animate__delay-1s' : ''}`}>
                <h2 className="">{t('how_it_works')}</h2>
                <p className="mb-3">{t('intro')}</p>
                <div className="accordion accordion-flush mb-3" id="accordionFlushExample">
                  <div className="accordion-item mb-3">
                    <h2 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <lord-icon src="https://cdn.lordicon.com/fylbbwsg.json" trigger="loop" delay="3000" style={{ width: '36px', height: '36px' }}></lord-icon>
                        {t('create_account')}
                        <lord-icon src="https://cdn.lordicon.com/gxyhnnbv.json" trigger="loop" delay="2000" style={{ width: '36px', height: '36px' }}></lord-icon>
                      </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">{t('create_account_details')}</div>
                    </div>
                  </div>
                  <div className="accordion-item mb-3">
                    <h2 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        <lord-icon src="https://cdn.lordicon.com/tmfvknde.json" trigger="loop" delay="3000" style={{ width: '36px', height: '36px' }}></lord-icon>
                        {t('choose_game')}
                        <lord-icon src="https://cdn.lordicon.com/gxyhnnbv.json" trigger="loop" delay="2000" style={{ width: '36px', height: '36px' }}></lord-icon>
                      </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">{t('choose_game_details')}</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        <lord-icon src="https://cdn.lordicon.com/xpvcuwnp.json" trigger="loop" delay="3000" style={{ width: '36px', height: '36px' }}></lord-icon>
                        {t('start_broadcast')}
                        <lord-icon src="https://cdn.lordicon.com/gxyhnnbv.json" trigger="loop" delay="2000" style={{ width: '36px', height: '36px' }}></lord-icon>
                      </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">{t('start_broadcast_details')}</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={`col-12 col-md-6 container animate__animated ${isActive && animateSection === section ? 'animate__zoomInUp animate__delay-1s' : ''}`}>
                          <Carousel fade>
                            <Carousel.Item interval={3000}>
                              <img
                                className="d-block w-75 mb-5 mx-auto custom-fade"
                                src="./images/img1.png"
                                alt="First slide"
                              />
                            </Carousel.Item>
                            <Carousel.Item interval={3000}>
                              <img
                                className="d-block w-75 mb-5 mx-auto custom-fade"
                                src="./images/img2.png"
                                alt="Second slide"
                              />
                            </Carousel.Item>
                          </Carousel>
                      </div> */}
            </div>
          </div>
        )}
        {/* Example content for section 3 */}
        {section === 'section3' && (
          <div className="container-fluid">
            <h1 className={`mt-5 animate__animated ${isActive && animateSection === section ? 'animate__zoomInDown' : ''}`}>{t('pricing')}</h1>
            <h6 className={`animate__animated ${isActive && animateSection === section ? 'animate__zoomInDown animate__delay-1s' : ''}`}>{t('all_plans_free_3_days')}</h6>
            <div className="container mt-2">
              <div className="row">
                <div className={`col-sm-6 animate__animated ${isActive && animateSection === section ? 'animate__rotateInDownRight animate__delay-1s' : ''}`}>
                  <div className="card1 text-center">
                    <div className="title">
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>
                      <h2 className='mb-2'>{t('week')}</h2>
                    </div>
                    <div className="price">
                      <h4>{t('week_price')}</h4>
                    </div>
                    <div className="option">
                      <ul>
                        <li><i className="fa fa-check" aria-hidden="true"></i> {t('week_benefit_1')}</li>
                        <li><i className="fa fa-check" aria-hidden="true"></i> {t('week_benefit_2')}</li>
                        <li><i className="fa fa-check" aria-hidden="true"></i> <del>{t('week_benefit_3')}</del></li>
                      </ul>
                    </div>
                    <a href="https://www.tiktok.com/@interactivetok.com" rel="noreferrer" target="_blank">{t('buy_now')}</a>
                  </div>
                </div>

                <div className={`col-sm-6 animate__animated ${isActive && animateSection === section ? 'animate__rotateInDownLeft animate__delay-1s' : ''}`}>
                  <div className="card1 text-center">
                    <div className="title">
                      <i className="fa fa-rocket" aria-hidden="true"></i>
                      <h2 className='mb-2'>{t('month')}</h2>
                    </div>
                    <div className="price">
                      <h4>{t('month_price')}</h4>
                    </div>
                    <div className="option">
                      <ul>
                        <li><i className="fa fa-check" aria-hidden="true"></i> {t('month_benefit_1')}</li>
                        <li><i className="fa fa-check" aria-hidden="true"></i> {t('month_benefit_2')}</li>
                        <li><i className="fa fa-check" aria-hidden="true"></i> {t('month_benefit_3')}</li>
                      </ul>
                    </div>
                    <a href="https://www.tiktok.com/@interactivetok.com" rel="noreferrer" target="_blank">{t('buy_now')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Example content for section 4 */}
        {section === 'section4' && (
          <div className="container">
            <div className="col-12 mx-auto">
              <div className="section-title text-center">
                <div className={`tooltip-container animate__animated ${isActive && animateSection === section ? 'animate__jackInTheBox animate__delay-1s' : ''}`}>
                  <div className="tooltip">
                    <div className="profile">
                      <div className="user">
                        <div className="imgTik"></div>
                        <div className="details">
                          <div className="name">InteractiveTok</div>
                          <div className="username">interactivetok.com@</div>
                        </div>
                      </div>
                      <div className="about">Followers +10 K</div>
                    </div>
                  </div>
                  <div className="text">
                    <a className="icon" target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@interactivetok.com">
                      <div className="layer">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span className="tiktokSVG">
                          <svg
                            fill="white"
                            className="svgIcon"
                            viewBox="0 0 448 512"
                            height="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <div className="text">Tiktok</div>
                    </a>
                  </div>
                </div>
                <br /> <br />
                <h2 className={`mt-5 animate__animated ${isActive && animateSection === section ? 'animate__jackInTheBox animate__delay-1s' : ''}`}>
                  {t('contact_us_tiktok')}
                </h2>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="landing-page">
      {sections.map((section, index) => (
        <div
          key={section}
          ref={(el) => (sectionRefs.current[index] = el)}
          className={`section ${section === activeSection ? 'visible' : 'hidden'} ${index === 0 ? 'bg-1' : index === 1 ? 'bg-2' : index === 2 ? 'bg-3' : 'bg-4'
            } text-white`}
        >
          {renderSectionContent(section)}
        </div>
      ))}
      <div className="nav-indicator">
        {sections.map((section, index) => renderNavIcon(section, index))}
      </div>
    </div>
  );
};

export default LandingPage;
