import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSound } from '../gameWrappers/SoundContext';
import lottie from 'lottie-web';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useMembers } from './MembersContext';
const TeamsLeaderboard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [leaders, setLeaders] = useState([]);
  const [winningTeam, setWinningTeam] = useState(null);
  const [totalTeamPoints, setTotalTeamPoints] = useState(0);
  const [teamLeader, setTeamLeader] = useState(null);
  const { lng, userId } = useParams();
  const navigate = useNavigate();
  const { playCheer } = useSound();
  const { t } = useTranslation();
  const totalQuestions = parseInt(queryParams.get('totalQuestions'), 10) || 0;
  const animationContainer = useRef(null);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        // Retrieve the data from local storage
        const storedData = JSON.parse(localStorage.getItem('members')) || {};
  
        // Create maps to store team points and individual performance
        const teamPointsQuestionMap = new Map();
        const teamMembersMap = new Map();
  
        // Calculate the total points for each team and store team members' data
        Object.values(storedData).forEach(member => {
          const { team, pointsQuestion, points, questions, nickname, profilePictureUrl, followRole } = member;
  
          // Skip members with followRole: 0
          if (followRole === 0) return;
  
          // Ensure that `questions` is an array before calling `filter`
          const correctAnswers = Array.isArray(questions) 
            ? questions.filter(q => q.answer === 'true').length 
            : 0;
  
          // Accumulate team points
          if (!teamPointsQuestionMap.has(team)) {
            teamPointsQuestionMap.set(team, 0);
          }
          teamPointsQuestionMap.set(team, teamPointsQuestionMap.get(team) + pointsQuestion);
  
          // Store members' data for each team
          if (!teamMembersMap.has(team)) {
            teamMembersMap.set(team, []);
          }
          teamMembersMap.get(team).push({
            uniqueId: member.uniqueId,
            nickname,
            points,
            pointsQuestion,
            correct_answers: correctAnswers,
            total_questions: totalQuestions,
            profilePictureUrl
          });
        });
  
        // Determine the winning team based on points
        let winningTeam = null;
        let maxPointsQuestion = -1; // so that zero points still sets a winner if no one has positive points
        teamPointsQuestionMap.forEach((pointsQuestion, team) => {
          if (pointsQuestion > maxPointsQuestion) {
            winningTeam = team;
            maxPointsQuestion = pointsQuestion;
          }
        });
        
  
        setWinningTeam(winningTeam);
  
        // Find the leader within the winning team (highest points)
        if (winningTeam) {
          const members = teamMembersMap.get(winningTeam);
          let leader = members.reduce((prev, current) => (prev.points > current.points) ? prev : current);
          setTeamLeader(leader);

          // Sum the total points for the winning team
          const totalPoints = members.reduce((sum, member) => sum + member.points, 0);
          setTotalTeamPoints(totalPoints); // Store the calculated total points
          
          // Sort the members by correct answers and update leaderboard
          members.sort((a, b) => {
            const correctDiff = b.correct_answers - a.correct_answers;
            if (correctDiff !== 0) {
              return correctDiff;
            } else {
              return b.points - a.points; // Secondary sort by points
            }
          });
  
          setLeaders(members);
        }
  
        playCheer(); // Play cheer sound on success
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };
  
    fetchLeaderboard();
  }, [userId, playCheer, totalQuestions]);
  

  const { setMembers } = useMembers();

  const handleNext = () => {
    localStorage.removeItem('members'); // Clear local storage
    setMembers({}); // Clear the members state in the context
    navigate(`/${lng}/${userId}/teamschallenge/teamssettings`); // Navigate to the new page
  };

  const crownImages = [
    require('./img/crown-gold.gif'),   // 1st position
    require('./img/crown-silver.gif'), // 2nd position
    require('./img/crown-bronze.gif')  // 3rd position
  ];

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await axios.get('/images/Animation.json', { responseType: 'json' });
        lottie.loadAnimation({
          container: animationContainer.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: response.data
        });
      } catch (error) {
        console.error('Error loading Lottie animation:', error);
      }
    };

    fetchAnimationData();

    return () => {
      lottie.destroy();
    };

  }, []);

  return (
    <div className="container mt-2">
      <div className="my-1">
        <div className="text-center row justify-content-around align-items-center mx-auto align-items-center" style={{ maxWidth: '290px' }}>
          <button className="col-6 fancy button" onClick={handleNext} style={{ width: '45%', height: '40px' }}>
            <h6 className="m-0 fontcolorYellow" style={{ position: 'relative', zIndex: '1', lineHeight: 'revert' }}>{t('start_new_challenge')}</h6>
          </button>
          <h4 className="col-6 m-0">{t('leaders')}</h4>
        </div>
      </div>
      <div className='celebration' ref={animationContainer}></div>
      {winningTeam && teamLeader && (
        <>
          <h4 className="text-center">{`${t('winning_team')} ${winningTeam} - ${totalTeamPoints}`}</h4>
          <div className="justify-content-center d-flex align-items-center">
          <img src={teamLeader.profilePictureUrl} alt="Pic" className="rounded-circle mx-1" width="30" height="30" />
          <h5 className="text-center font-weight-bold fontcolorWhite m-0">{`${teamLeader.nickname}`} - {`${teamLeader.points}`}</h5>
          </div>
        </>
      )}
      <TopThreeLeaders leaders={leaders.slice(0, 3)} crownImages={crownImages} />
      <RemainingLeaders leaders={leaders.slice(3)} />
    </div>
  );
};

const TopThreeLeaders = ({ leaders, crownImages }) => {
  const [showTop3, setShowTop3] = useState(false);

  useEffect(() => {
    setShowTop3(true);
  }, []);

  return (
    <div className={`row justify-content-center my-4 podium ${showTop3 ? 'show' : ''}`}>
      {leaders.map((leader, index) => (
        <div key={index} className={`p-1 col-md-4 col-sm-4 col-4 text-center position-relative podium-position position-${index + 1}`}>
          <img className='crown' src={crownImages[index]} alt="Profile" />
          <img
            src={leader.profilePictureUrl}
            alt="Profile"
            className="rounded-circle mb-2"
            width="50"
            onError={(e) => {
              e.target.onerror = null; // Prevents infinite loop
              e.target.src = 'fallback-image-url'; // Use a fallback image URL here
            }}
          />
          <h6>{leader.nickname}</h6>
          <h6>{leader.correct_answers}/{leader.total_questions} - {leader.pointsQuestion}</h6>
        </div>
      ))}
    </div>
  );
};

const RemainingLeaders = ({ leaders }) => {
  const [showRest, setShowRest] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRest(true);
    }, 4500); // Delay to match the podium transition duration

    return () => clearTimeout(timer);
  }, []);

  return (
    showRest && (
      <ul className="bgTransparent leaderboard-list mx-auto slow-motion custom-scrollbar rounded-3 p-1" style={{ maxWidth: '280px', maxHeight: '240px' }}>
        {leaders.map((leader, index) => (
          <li key={index + 3} className="d-flex justify-content-center align-items-end mb-1 pb-1 border-bottom border-white">
            <h6 className='col-1'>{index + 4}</h6>
            <img
              src={leader.profilePictureUrl}
              alt="Profile"
              className="rounded-circle col-2"
              style={{ width: '40px' }}
              onError={(e) => {
                e.target.onerror = null; // Prevents infinite loop
                e.target.src = 'fallback-image-url'; // Use a fallback image URL here
              }}
            />
            <h6 className='col-7'>{leader.nickname}</h6>
            <h6 className='col-2 m-0'>{leader.correct_answers}/{leader.total_questions} <br /> {leader.pointsQuestion}</h6>
          </li>
        ))}
      </ul>
    )
  );
};

export default TeamsLeaderboard;
