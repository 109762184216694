// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDuBvGXijbtlayMRTXPbzv8V63zzh-zauU",
    authDomain: "interactive-tok.firebaseapp.com",
    projectId: "interactive-tok",
    storageBucket: "interactive-tok.appspot.com",
    messagingSenderId: "159817167224",
    appId: "1:159817167224:android:197d49cf03b8b6d01c988d",
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
