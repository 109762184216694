import React, { useState, useEffect, useCallback } from 'react';
import {useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';

const GameHeader = () => {
  const [userId, setUserId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [tiktokConnected, setTiktokConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [connectError, setConnectError] = useState(null);
  const location = useLocation();
  const isBrainChallenge = location.pathname.includes('brainchallenge');
  const isNumberChallenge = location.pathname.includes('numberchallenge');
  const isMemoryChallenge = location.pathname.includes('memorychallenge');
  const isTeamsChallenge = location.pathname.includes('teamschallenge');
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [language] = useState(i18n.language);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId) {
        setUserId(storedUserId);
        fetchUserData(storedUserId);

        const storedStatus = localStorage.getItem('tiktokStatus');
        if (storedStatus) {
          const { connected, error, roomId } = JSON.parse(storedStatus);
          setTiktokConnected(connected);
          setConnectError(error ? 'خطأ بالاتصال' : null);
          if (roomId) {
            // console.log(`Connected to Room ID: ${roomId}`);
          }
        }
      }

      const socket = io('https://backend-tok.weblab99.com');

      socket.on('connect', () => {
        // console.log('Connected to WebSocket server');
      });

      socket.on('disconnect', () => {
        // console.log('Disconnected from WebSocket server');
      });

      socket.on('tiktokConnected', (data) => {
        // console.log('tiktokConnected event data:', data);
        setTiktokConnected(true);
        setConnecting(false);
        setConnectError(null);
        saveStatusToLocalStorage(true, false, data.roomId);
        socket.emit('joinRoom', { userId: storedUserId, roomId: data.roomId });
      });

      socket.on('tiktokDisconnected', () => {
        setTiktokConnected(false);
        setConnecting(false);
        setConnectError('Connection lost. Please try reconnecting.');
        saveStatusToLocalStorage(false, true, null);
      });

      socket.on('tiktokError', ({ message }) => {
        setConnecting(false);
        setConnectError(message);
        setTiktokConnected(false);
        saveStatusToLocalStorage(false, true, null);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, []);

  const checkConnectionStatus = useCallback(async (userId) => {
    try {
      const res = await axios.get(`https://backend-tok.weblab99.com/tiktok/connectionStatus/${userId}`);
      const { connected, error, disconnected, roomId } = res.data;

      if (connected) {
        setTiktokConnected(true);
        setConnecting(false);
        setConnectError(null);
      } else if (error) {
        setTiktokConnected(false);
        setConnecting(false);
        setConnectError('خطأ بالاتصال');
      } else if (disconnected) {
        setTiktokConnected(false);
        setConnecting(false);
        setConnectError(null);
      }
      saveStatusToLocalStorage(connected, error, roomId);
    } catch (error) {
      // console.error('Error checking TikTok connection status:', error);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      const interval = setInterval(() => {
        checkConnectionStatus(userId);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [userId, checkConnectionStatus]);

  const saveStatusToLocalStorage = (connected, error, roomId) => {
    localStorage.setItem('tiktokStatus', JSON.stringify({ connected, error, roomId }));
  };

  const fetchUserData = async (userId) => {
    try {
      const res = await axios.get(`https://backend-tok.weblab99.com/users/${userId}`);
      setUserData(res.data);
    } catch (error) {
      // console.error('Error fetching user data:', error);
    }
  };

  const tiktokConnect = async (username, userId) => {
    try {
      setConnecting(true);
      const res = await axios.get('https://backend-tok.weblab99.com/comments', {
        params: { username, userId }
      });

      if (res.data && res.data.roomId) {
        // console.log(`Started listening to TikTok live stream for user ${userId} with Room ID ${res.data.roomId}`);
        setConnectError(null);
        setConnecting(false);
        setTiktokConnected(true);
        saveStatusToLocalStorage(true, false, res.data.roomId);

        const socket = io('https://backend-tok.weblab99.com');
        socket.emit('joinRoom', { userId, roomId: res.data.roomId });
      } else {
        // console.error('Room ID is undefined in the response');
        setConnectError('Failed to retrieve Room ID.');
        setConnecting(false);
        setTiktokConnected(false);
        saveStatusToLocalStorage(false, true, null);
      }
    } catch (error) {
      // console.error('Error starting TikTok live stream:', error);
      setConnectError('Failed to connect to TikTok. Please try again later.');
      setConnecting(false);
      setTiktokConnected(false);
      saveStatusToLocalStorage(false, true, null);
    }
  };

  // function dashboard if url isAutoMode=true then make it isAutoMode=false and go to /${language}/dashboard/${userId}
  const handleUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAutoMode = urlParams.get('isAutoMode');
    if (isAutoMode === 'true') {
      urlParams.set('isAutoMode', 'false');
      window.location.search = urlParams.toString();
    }
    navigate(`/${language}/dashboard/${userId}`);
  };

  useEffect(() => {
    // Check if token exists in localStorage when component mounts
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token); // Set isAuthenticated to true if token exists, false otherwise

    // Fetch userId from localStorage
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId); // Set userId state if it exists in localStorage
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from local storage
    localStorage.removeItem('userId'); // Remove userId from local storage
    setIsAuthenticated(false); // Update isAuthenticated state
    setUserId(null); // Clear userId state
    window.location.href = `/${language}/login`; // Redirect to login page
  };

  const isAutoMode = new URLSearchParams(window.location.search).get('isAutoMode') === 'true';


  return (
    <div>
      <nav id="game-header" className="navbar navbar-game">
      <button
        onClick={handleUrl}
        id="start"
        className={`btn-home button fancy ${isAutoMode ? 'bg-success' : ''}`}
        style={{ padding: 'unset' }}
      >
        <span className="fontcolorYellow" style={{ position: 'relative', zIndex: '1' }}>
          <i className="bi bi-house-heart fs-5"></i>
        </span>
      </button>
      {isAuthenticated && ( location.pathname.includes('dashboard') || location.pathname.includes('Dashboard') ) && (
  <div className="mx-1 py-1 px-2 btn btn-outline-light">
    <button className="btn btn-link nav-link" onClick={handleLogout}>
      {t('logout')}
    </button>
  </div>
)}
        {isBrainChallenge && (
          <div>
            <span className="water">{t('brain_challenge_h')}</span>
            <span className="water">{t('brain_challenge_h')}</span>
          </div>
        )}
        {isNumberChallenge && (
          <div>
            <span className="water">{t('number_challenge_h')}</span>
            <span className="water">{t('number_challenge_h')}</span>
          </div>
        )}
        {isMemoryChallenge && (
          <div>
            <span className="water">{t('memory_challenge_h')}</span>
            <span className="water">{t('memory_challenge_h')}</span>
          </div>
        )}
        {isTeamsChallenge && location.pathname.includes('teamssettings') ? (
          <div>
            <span className="fontcolorYellow fw-bold fs-6">  انضم لاحد الفريقين بكتابة <span className="fontcolorWhite">انضم</span> </span>
          </div>
        ) : isTeamsChallenge && (
          <div>
            <span className="water">{t('brain_challenge_h')}</span>
            <span className="water">{t('brain_challenge_h')}</span>
          </div>
        )}
        {!tiktokConnected && !connecting && userData && (
          <div>
            <button className={`py-0 px-2 btn ${connectError ? 'btn-warning' : 'btn-danger'}`} onClick={() => tiktokConnect(userData.username, userId)}>
              {t(connectError ? 'connect_error' : 'connect')}
            </button>
          </div>
        )}
        {connecting && (
          <button className="py-0 px-2 btn btn-info" disabled>{t('connecting')}</button>
        )}
        {tiktokConnected && (
          <div>
            <button className="py-0 px-2 btn btn-success">{t('connected')}</button>
          </div>
        )}
      </nav>
    </div>
  );
};

export default GameHeader;
