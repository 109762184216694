import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import brainImage from '../images/brain.png';
import numberImage from '../images/number.png';
import memoryImage from '../images/memory.png';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();
  const { lng, userId } = useParams();
  const [userData, setUserData] = useState(null);
  const isAvailable = false; // or false based on your logic

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`https://backend-tok.weblab99.com/users/${userId}`);
        setUserData(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, [userId]);

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    return format(date, ' yyyy-MM-dd'); // Customize the format as needed
  };

  return (
    <div className="container" style={{ maxWidth: '450px' }}>
      {/* <h2 className="my-3 fontWhite">{t('user_dashboard')}</h2> */}
      {userData ? (
        <>
          <div id="dash-table" className="container text-center bgTransparent rounded-4 p-1 mb-1" >
            <div className="row p-0">
              <div className="col p-0 fontcolorWhite">{t('username')}</div>
              <div className="col p-0 fontcolorYellow">{userData.username}</div>
              {/* <div className="col fontcolorWhite">{t('email')}</div>
              <div className="col fontcolorYellow">{userData.email}</div> */}
            </div>
          </div>
          <div className="container text-center bgTransparent rounded-4 p-1">
            <div className="row align-middle">
              <div className="col p-0 fontcolorWhite">{t('status')}</div>
              <div className="col p-0 fontcolorYellow">
                <span className={`badge ${userData.active ? 'bg-success px-3 py-1' : 'bg-warning px-3 py-1'}`}>
                  {userData.active ? t('active') : t('inactive')}
                </span>
              </div>
              <div className="col fontcolorWhite">{t('active_until')}</div>
              <div className="col fontcolorYellow">{formatDateTime(userData.active_until)}</div>
            </div>
          </div>
          {userData.active ? (
            <div className="games text-center mt-3">
              <h5 className="fontcolorWhite">{t('game_desc')}</h5>
              <div className="container row justify-content-around p-2 mx-auto" style={{ maxWidth: '450px' }}>
                <div className="row row-cols-2 g-1">
                  <div className="card col bgTransparent fontcolorWhite py-1">
                    <Link
                      to={`/${lng}/${userId}/brainchallengeupdate/startgameupdate`}
                      state={{ username: userData.username }}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <img src={brainImage} className="card-img-top" alt="Game Title" />
                      <div className="card-body py-1 px-0">
                        <h5 className="card-title fontcolorYellow">{t('brain_challenge_update')}</h5>
                        {/* <h6 className="card-text">{t('brain_challenge_desc')}</h6> */}
                      </div>
                    </Link>
                  </div>

                  <div className="card col bgTransparent fontcolorWhite py-1">
                    <Link
                      to={`/${lng}/${userId}/teamschallenge/teamsstartgame`}
                      state={{ username: userData.username }}
                      className=""
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <img src={brainImage} className="card-img-top" alt="Game Title" />
                      <div className="card-body p-1">
                        <h5 className="card-title fontcolorYellow">{t('brain_challenge_teams')}</h5>
                        {/* <h6 className="card-text">{t('brain_challenge_desc')}</h6> */}
                      </div>
                    </Link>
                  </div>
                  <div className="card col bgTransparent fontcolorWhite py-1">
                    <Link
                      to={`/${lng}/${userId}/millionchallenge/startgamemillion`}
                      state={{ username: userData.username }}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <img src={brainImage} className="card-img-top" alt="Game Title" />
                      <div className="card-body p-1">
                        <h5 className="card-title fontcolorYellow">{t('brain_challenge_million')} <span className="fontcolorWhite">جديد</span></h5>
                        {/* <h6 className="card-text">{t('brain_challenge_desc')}</h6> */}
                      </div>
                    </Link>
                  </div>
                  <div className="card col bgTransparent fontcolorWhite py-1">
                    <Link
                      to={`/${lng}/${userId}/brainchallenge/startgame`}
                      state={{ username: userData.username }}
                      className=""
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <img src={brainImage} className="card-img-top" alt="Game Title" />
                      <div className="card-body p-1">
                        <h5 className="card-title fontcolorYellow">{t('brain_challenge')}</h5>
                        {/* <h6 className="card-text">{t('brain_challenge_desc')}</h6> */}
                      </div>
                    </Link>
                  </div>
                  <div className="card col bgTransparent fontcolorWhite py-1">
                    <Link
                      to={`/${lng}/${userId}/numberchallenge/numstartgame`}
                      state={{ username: userData.username }}
                      className=""
                      style={{ textDecoration: 'none', color: 'inherit', pointerEvents: isAvailable ? 'auto' : 'none', }}
                    >
                      <img src={numberImage} className="card-img-top" alt="Game Title" />
                      <div className="card-body p-1">
                        <h5 className="card-title fontcolorWhite">{t('eye_challenge')}</h5>
                        {/* <h6 className="card-text">{t('eye_challenge_desc')}</h6> */}
                      </div>
                    </Link>
                  </div>
                  <div className="card col bgTransparent fontcolorWhite py-1">
                    <Link
                      to={`/${lng}/${userId}/memorychallenge/memstartgame`}
                      state={{ username: userData.username }}
                      className=""
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        pointerEvents: isAvailable ? 'auto' : 'none', // Disable click if not available
                      }}
                    >
                      <img src={memoryImage} className="card-img-top" alt="Game Title" />
                      <div className="card-body p-1">
                        <h5 className="card-title fontcolorWhite">{t('memory_challenge')} </h5>
                        {/* <h6 className="card-text">{t('memory_challenge_desc')}</h6> */}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="games text-center mt-3">
              <div className="subscribe text-center bg-warning p-2 fontcolorWhite col-10 col.sm-10 col-md-6 mx-auto rounded-3">
                <span>{t('subscribe_message')}</span>
              </div>
              <div className="mt-5 tooltip-container animate__animated animate__jackInTheBox animate__delay-1s">
                <div className="tooltip">
                  <div className="profile">
                    <div className="user">
                      <div className="imgTik"></div>
                      <div className="details">
                        <div className="name">InteractiveTok</div>
                        <div className="username">interactivetok.com@</div>
                      </div>
                    </div>
                    <div className="about">Followers +10 K</div>
                  </div>
                </div>
                <div className="text">
                  <a className="icon" target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@interactivetok.com">
                    <div className="layer">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span className="tiktokSVG">
                        <svg
                          fill="white"
                          className="svgIcon"
                          viewBox="0 0 448 512"
                          height="1.5em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div className="text">Tiktok</div>
                  </a>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <p>{t('loading')}</p>
      )}
    </div>
  );
};

export default Dashboard;
