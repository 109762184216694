import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSound } from '../gameWrappers/SoundContext';
import axios from 'axios';
import lottie from 'lottie-web';
import { useTranslation } from 'react-i18next';

const NumLeaderboard = () => {
  const [leaders, setLeaders] = useState([]);
  const { lng, userId } = useParams();
  const navigate = useNavigate();
  const { playCheer } = useSound();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.get('https://backend-tok.weblab99.com/leaderboard', {
          params: { userId }
        });
        setLeaders(response.data);
        // Ensure playCheer is called in response to user interaction
        playCheer();
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    fetchLeaderboard();
  }, [userId, playCheer]);

  const handleNext = () => {
    navigate(`/${lng}/${userId}/numberchallenge/numsettings`);
  };

  const crownImages = [
    require('./img/crown-gold.gif'),   // 1st position
    require('./img/crown-silver.gif'), // 2nd position
    require('./img/crown-bronze.gif')  // 3rd position
  ];
  const animationContainer = useRef(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await axios.get('/images/Animation.json', { responseType: 'json' });
        lottie.loadAnimation({
          container: animationContainer.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: response.data
        });
      } catch (error) {
        console.error('Error loading Lottie animation:', error);
      }
    };

    fetchAnimationData();

    return () => {
      lottie.destroy();
    };

  }, []);

  return (
    <div className="container mt-2">
      <div className="my-3">

        <div className="text-center row justify-content-around align-items-center">
          <button className="col-6 fancy button" onClick={handleNext} style={{ width: '40%', height: '40px' }}>
            <h6 className="m-0 fontcolorYellow" style={{ position: 'relative', zIndex: '1', lineHeight: 'revert' }}>{t('start_new_challenge')}</h6>
          </button>
          <h4 className="col-6">{t('leaders')}</h4>
        </div>
      </div>
      <div className='celebration' ref={animationContainer}></div>
      <TopThreeLeaders leaders={leaders.slice(0, 3)} crownImages={crownImages} />
      <RemainingLeaders leaders={leaders.slice(3)} />

    </div>
  );
};

const TopThreeLeaders = ({ leaders, crownImages }) => {
  const [showTop3, setShowTop3] = useState(false);
  

  useEffect(() => {
    setShowTop3(true);


  }, []);

  return (
    <div className={`row justify-content-center mb-4 podium ${showTop3 ? 'show' : ''}`}>
      {leaders.map((leader, index) => (
        <div key={index} className={`p-1 col-md-4 col-sm-4 col-4 text-center position-relative podium-position position-${index + 1}`}>
          <img className='crown' src={crownImages[index]} alt={`Crown ${index + 1}`} />
          <img src={leader.tik_pic} alt="Profile" className="rounded-circle mb-2" width="50" />
          <h6>{leader.tik_nickname}</h6>
          <h6>{leader.correct_answers}/{leader.total_questions}</h6>
        </div>
      ))}
      
    </div>
  );
};

const RemainingLeaders = ({ leaders }) => {
  const [showRest, setShowRest] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRest(true);
    }, 4500); // Delay to match the podium transition duration

    return () => clearTimeout(timer);
  }, []);

  return (
    showRest && (
      <ul className="bgTransparent leaderboard-list slow-motion custom-scrollbar rounded-3 p-1">
        {leaders.map((leader, index) => (
          <li key={index + 3} className="d-flex justify-content-center align-items-end mb-1 pb-1 border-bottom border-white">
            <h6 className='col-1'>{index + 4}</h6>
            <img src={leader.tik_pic} alt="Profile" className="rounded-circle col-2" style={{ width: '40px' }} />
            <h6 className='col-7'>{leader.tik_nickname}</h6>
            <h6 className='col-2'>{leader.correct_answers}/{leader.total_questions}</h6>
          </li>
        ))}
      </ul>
    )
  );
};

export default NumLeaderboard;
