import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSocket } from '../../utils/socket';
import { useMembers } from './MembersContext';
import { useLocation } from 'react-router-dom';


const TeamsGameColumn = ({ children }) => {
  const { userId } = useParams();
  const team1 = 'ازرق';
  const team2 = 'احمر';
  const { members, setMembers, teamLeaders, setTeamLeaders } = useMembers();

  // State to keep track of team counts
  const [team1Count, setTeam1Count] = useState(0);
  const [team2Count, setTeam2Count] = useState(0);

  const [showPopup, setShowPopup] = useState(false);
  const [newLeaders, setNewLeaders] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [likeCounts, setLikeCounts] = useState({});

  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);

  const location = useLocation();

  const handleApproval = (uniqueId, team) => {
    setMembers((prevMembers) => {
      const memberData = pendingApprovals.find((member) => member.uniqueId === uniqueId);
      if (!memberData) return prevMembers;

      const updatedMembers = {
        ...prevMembers,
        [uniqueId]: {
          ...prevMembers[uniqueId],
          team,
          points: 0,
          nickname: memberData.nickname, // Ensure nickname is updated
          profilePictureUrl: memberData.profilePictureUrl, // Ensure profile picture is updated
          followRole: memberData.followRole,
          pointsQuestion: 0,
        },
      };
      return updatedMembers;
    });

    // Remove member from pending approvals
    setPendingApprovals((prev) => prev.filter((member) => member.uniqueId !== uniqueId));
    if (pendingApprovals.length === 1) setShowApprovalPopup(false); // Close popup if no more pending
  };



  const handleRejection = (uniqueId) => {
    // Remove member from pending approvals
    setPendingApprovals((prev) => prev.filter((member) => member.uniqueId !== uniqueId));
    if (pendingApprovals.length === 1) setShowApprovalPopup(false); // Close popup if no more pending
  };

  useEffect(() => {
    const socket = getSocket();

    socket.on('connect', () => {
      socket.emit('joinRoom', { userId });
    });

    socket.on('chat', (data) => {
      const { uniqueId, comment, nickname, profilePictureUrl, followRole } = data.data || {};

      // Only add to pending approvals if "انضم" is in the comment
      if (comment.includes('انضم') && (followRole === 1 || followRole === 2)) {
        setPendingApprovals((prev) => {
          // Check if the member is already pending approval
          const isAlreadyPending = prev.some((member) => member.uniqueId === uniqueId);
      
          // Add the member only if they are not already pending approval
          if (!isAlreadyPending) {
            return [
              ...prev,
              { uniqueId, nickname, profilePictureUrl, followRole },
            ];
          }
          return prev; // Return the existing state if already pending
        });
      
        if (location.pathname.includes('teamssettings') && !pendingApprovals.some((member) => member.uniqueId === uniqueId)) {
          setShowApprovalPopup(true);
        }
      }
      
    });

    socket.on('like', (data) => {
      // console.log('Received like data:', data);
      const { uniqueId, likeCount } = data;
      setMembers((prevMembers) => {
        if (prevMembers[uniqueId]) {
          const newPoints = (prevMembers[uniqueId].points || 0) + likeCount;
          // console.log('Updated points for member:', uniqueId, 'New Points:', newPoints);
          return {
            ...prevMembers,
            [uniqueId]: { ...prevMembers[uniqueId], points: newPoints },
          };
        }
        return prevMembers;
      });
    });
    

    socket.on('gift', (data) => {
      const { uniqueId, diamondCount } = data;
      setMembers((prevMembers) => {
        if (prevMembers[uniqueId]) {
          const newPoints = (prevMembers[uniqueId].points || 0) + diamondCount * 2000 / 2;
          return {
            ...prevMembers,
            [uniqueId]: { ...prevMembers[uniqueId], points: newPoints },
          };
        }
        return prevMembers;
      });
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    return () => {
      socket.off('chat');
      socket.off('like');
      socket.off('gift');
      socket.off('disconnect');
    };
  }, [userId, setMembers, location.pathname, pendingApprovals]);

  useEffect(() => {
    const sortedMembers = Object.values(members).sort((a, b) => b.points - a.points);
    const leader1 = sortedMembers.find((member) => member.team === team1) || null;
    const leader2 = sortedMembers.find((member) => member.team === team2) || null;
  
    const newLeaders = { team1: leader1, team2: leader2 };
  
    // تحقق إذا تغير القائد للفريق
    const leader1Changed = teamLeaders.team1?.uniqueId !== newLeaders.team1?.uniqueId;
    const leader2Changed = teamLeaders.team2?.uniqueId !== newLeaders.team2?.uniqueId;
  
    if (leader1Changed || leader2Changed) {
      console.log('Leader changed:', newLeaders);
  
      // تحديث القادة وإظهار الـ Popup
      setNewLeaders(newLeaders);
      setShowPopup(false);
  
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 4000);
  
      return () => clearTimeout(timer); // تنظيف الـ timer
    }
  
    // تحديث القادة فقط بدون إظهار Popup
    setTeamLeaders((prevLeaders) => {
      if (leader1Changed || leader2Changed) {
        return newLeaders;
      }
      return prevLeaders;
    });
  }, [members, team1, team2, teamLeaders, setTeamLeaders]);
 
  

  useEffect(() => {
    // Count members in each team
    const team1Members = Object.values(members).filter((member) => member.team === team1).length;
    const team2Members = Object.values(members).filter((member) => member.team === team2).length;

    // Update the state with new counts
    setTeam1Count(team1Members);
    setTeam2Count(team2Members);
  }, [members, team1, team2]);

  const renderTeamMembers = (team) => {
    const teamMembers = Object.values(members)
      .filter((member) => member.team === team)
      .sort((a, b) => b.points - a.points);
  
    return teamMembers.map((member, index) => {
      const nickname = member.nickname || 'Unknown';
      const isSingleWord = nickname.trim().split(/\s+/).length === 1;
  
      return (
        <li
          key={member.uniqueId || `${member.nickname}-${team}-${index}`}
          className="fontcolorYellow border-bottom border-dark-subtle mb-1"
          style={{ color: 'white', textAlign: 'center' }}
        >
          <div className="text-center mb-2">
            {member.profilePictureUrl ? (
              <img
                src={member.profilePictureUrl}
                alt="Pic"
                className="rounded-circle mb-2"
                width="40"
              />
            ) : (
              <div
                className="placeholder-img mb-2"
                style={{ width: '40px', height: '40px', backgroundColor: 'grey', borderRadius: '50%' }}
              ></div>
            )}
  
            <span
              style={
                isSingleWord
                  ? {
                      display: 'inline-block',
                      fontSize: '0.5rem',
                      width: '60px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }
                  : {
                      display: 'inline-block',
                      fontSize: '0.5rem',
                      width: '60px',
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      wordWrap: 'break-word',
                    }
              }
            >
              {nickname}
            </span>
            <h6>{member.points || 0}</h6>
          </div>
        </li>
      );
    });
  };
  

  return (
    <div className="body-game">
      <div className="text-center w-75 mx-auto">{children}</div>
      {showApprovalPopup && pendingApprovals.length > 0 && (
        <div className="popup-overlay">
          <div className="popup-content-wrapper py-2 px-3">
            {pendingApprovals.map((member, index) => (
              <div key={index} className="">
                <img src={member.profilePictureUrl} alt="Profile" className="rounded-circle mb-2" width="40" />
                <p>{member.nickname}</p>
                <button
                  className="btn btn-primary"
                  onClick={() => handleApproval(member.uniqueId, team1)}
                >
                  {team1}
                </button>
                <button
                  className="btn btn-danger mx-1"
                  onClick={() => handleApproval(member.uniqueId, team2)}
                >
                  {team2}
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => handleRejection(member.uniqueId)}
                >
                  رفض
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      {showPopup && newLeaders.team1 && newLeaders.team2 && (
        <div className="popup-overlay-leaders">
          <div className="popup-content-wrapper-leaders">
            <h2>قادة جدد</h2>
            <div className="leaders">
              <div className="leader">
                <img src={newLeaders.team1.profilePictureUrl} alt="Pic" />
                <p>{newLeaders.team1.nickname}</p>
              </div>
              <span>vs</span>
              <div className="leader">
                <img src={newLeaders.team2.profilePictureUrl} alt="Pic" />
                <p>{newLeaders.team2.nickname}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <ul className='bgTransparent p-1 rounded-3 text-center'
        style={{
          listStyleType: 'none',
          position: 'absolute',
          width: '65px',
          top: '150px',
          right: '11px',
          zIndex: '1',
          textAlign: 'center',
        }}
      >
        <li style={{ display: 'inline-block', color: 'white' }}>
          <span className='text-primary border-bottom border-primary mb-2' style={{ display: 'block', fontSize: '1rem', textAlign: 'center' }}>{team1Count} - {team1}</span>
          <ul className='right-side'>{renderTeamMembers(team1)}</ul>
        </li>
      </ul>
      <ul className='bgTransparent p-1 rounded-3 text-center'
        style={{
          listStyleType: 'none',
          position: 'absolute',
          width: '65px',
          top: '150px',
          left: '11px',
          zIndex: '1',
          textAlign: 'center',
        }}
      >
        <li style={{ display: 'inline-block', color: 'white' }}>
          <span className='text-danger border-bottom border-danger mb-2' style={{ display: 'block', fontSize: '1rem', textAlign: 'center' }}>{team2Count} - {team2}</span>
          <ul className='left-side'>{renderTeamMembers(team2)}</ul>
        </li>
      </ul>
    </div>
  );
};

export default TeamsGameColumn;
