import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCamera } from './CameraContext';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useSound } from './SoundContext';
import { useTranslation } from 'react-i18next';
import { getSocket } from '../../utils/socket';
import ColorPicker from './ColorPicker';
import VotingPopup from './VotingPopup';

const GameWrapper = ({ children }) => {
  const { userId } = useParams();
  const { camera, setCamera } = useCamera();
  const initialVolume = localStorage.getItem('backgroundVolume') ? parseInt(localStorage.getItem('backgroundVolume')) : 0;
  const [backgroundVolume, setBackgroundVolume] = useState(initialVolume);
  const [isVolumePopupVisible, setIsVolumePopupVisible] = useState(false);
  const [isLikesVisible, setIsLikesVisible] = useState(false);
  const { t } = useTranslation();
  const [selectedAudio, setSelectedAudio] = useState('backgroundAudio');
  const [userLikes, setUserLikes] = useState({});
  const [topLikers, setTopLikers] = useState([]);
  const [showPopupLikes, setShowPopupLikes] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [userFollowers, setUserFollowers] = useState({});
  const [showPopupFollowers, setShowPopupFollowers] = useState(false);
  const [popupDataFollowers, setPopupDataFollowers] = useState({});
  const { playBackground, pauseBackground, setVolume, playLike, playFollow, playGift } = useSound();
  const [userGifters, setUserGifters] = useState({});
  const [showPopupGifters, setShowPopupGifters] = useState(false);
  const [popupDataGifters, setPopupDataGifters] = useState({});
  const [isGiftsVisible, setIsGiftsVisible] = useState(false);
  const [topGifters, setTopGifters] = useState([]);
  const [showLikes, setShowLikes] = useState(true);
  const [showFollowers, setShowFollowers] = useState(true);
  const [showGifters, setShowGifters] = useState(true);
  const [isVotingPopupVisible, setIsVotingPopupVisible] = useState(false);


  useEffect(() => {
    const socket = getSocket();

    socket.on('connect', () => {
      console.log('Connected to socket');
      socket.emit('joinRoom', { userId });
    });

    socket.on('like', (data) => {
      // console.log('Like received:', data);
      setUserLikes((prevLikes) => {
        const newLikes = {
          ...prevLikes,
          [data.uniqueId]: {
            nickname: data.nickname,
            profilePictureUrl: data.profilePictureUrl,
            totalLikeCount: (prevLikes[data.uniqueId]?.totalLikeCount || 0) + data.likeCount,
            likesSinceLastPopup: (prevLikes[data.uniqueId]?.likesSinceLastPopup || 0) + data.likeCount, // Track likes since the last popup
          },
        };
        if (showLikes) {
          if (newLikes[data.uniqueId].likesSinceLastPopup >= 200) {
            setPopupData({
              nickname: data.nickname,
              profilePictureUrl: data.profilePictureUrl,
            });
            setShowPopupLikes(true);

            playLike();

            // Reset the count for likes since the last popup
            newLikes[data.uniqueId].likesSinceLastPopup = 0;

            setTimeout(() => {
              setShowPopupLikes(false);
            }, 5000);
          }
        }

        // Update top 10 likers
        const sortedLikers = Object.values(newLikes)
          .sort((a, b) => b.totalLikeCount - a.totalLikeCount)
          .slice(0, 10);

        setTopLikers(sortedLikers);

        return newLikes;
      });
    });

    socket.on('follow', (data) => {
      // console.log('Follow received:', data);
      if (showFollowers) {
        setUserFollowers((prevFollowers) => {
          const newFollowers = {
            ...prevFollowers,
            [data.uniqueId]: {
              nickname: data.nickname,
              profilePictureUrl: data.profilePictureUrl,
            },
          };

          setPopupDataFollowers({
            nickname: data.nickname,
            profilePictureUrl: data.profilePictureUrl,
          });

          setShowPopupFollowers(true);

          playFollow();

          setTimeout(() => {
            setShowPopupFollowers(false);
          }, 5000);

          return newFollowers;
        });
      }
    });

    socket.on('gift', (data) => {
      //  console.log('Gift event received:', data);
      if (showGifters) {
        setUserGifters((prevGifters) => {
          const newGifters = {
            ...prevGifters,
            [data.uniqueId]: {
              nickname: data.nickname,
              profilePictureUrl: data.profilePictureUrl,
              giftId: data.giftId,
              giftType: data.giftType,
              giftName: data.giftName,
              giftPictureUrl: data.giftPictureUrl,
              diamondCount: data.diamondCount,
              repeatCount: data.repeatCount,
              totalGiftCount: (prevGifters[data.uniqueId]?.totalGiftCount || 0) + data.diamondCount,
            },
          };

          setPopupDataGifters({
            nickname: data.nickname,
            profilePictureUrl: data.profilePictureUrl,
            giftId: data.giftId,
            giftType: data.giftType,
            giftName: data.giftName,
            giftPictureUrl: data.giftPictureUrl,
            diamondCount: data.diamondCount,
            repeatCount: data.repeatCount,
          });

          setShowPopupGifters(true);

          playGift();

          setTimeout(() => {
            setShowPopupGifters(false);
          }, 10000);

          const sortedGifters = Object.values(newGifters)
            .sort((a, b) => b.totalGiftCount - a.totalGiftCount)
            .slice(0, 3);

          setTopGifters(sortedGifters);

          return newGifters;
        });
      }
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    return () => {
      socket.off('like');
      socket.off('follow');
      socket.off('gift');
      socket.off('disconnect');
    };
  }, [userId, playLike, playFollow, showLikes, showFollowers, showGifters, playGift, setVolume]);

  useEffect(() => {
    const savedVolume = localStorage.getItem('backgroundVolume');
    if (savedVolume !== null) {
      const volume = parseInt(savedVolume);
      setBackgroundVolume(volume);
      setVolume(volume);
    }
  }, [setVolume]);

  const handleVolumeChange = (e) => {
    const newVolume = parseInt(e.target.value);

    if (newVolume > 0) {
      playBackground(selectedAudio);
    } else if (newVolume === 0) {
      pauseBackground();
    }

    setBackgroundVolume(newVolume);
    setVolume(newVolume);
    localStorage.setItem('backgroundVolume', newVolume);
  };

  const toggleVolumePopup = () => {
    setIsVolumePopupVisible(!isVolumePopupVisible);
  };

  const toggleLikesPopup = () => {
    setIsLikesVisible(!isLikesVisible);
  };

  const toggleGiftsPopup = () => {
    setIsGiftsVisible(!isGiftsVisible);
  };

  const handleAudioChange = (e) => {
    setSelectedAudio(e.target.value);
    playBackground(e.target.value);
  };

  const handleLikesChange = (e) => {
    setShowLikes(e.target.checked);
  };

  const handleFollowersChange = (e) => {
    setShowFollowers(e.target.checked);
  };

  const handleGiftersChange = (e) => {
    setShowGifters(e.target.checked);
  };

  // Reset handler for likes
  const handleResetLikes = () => {
    setUserLikes({});
    setTopLikers([]);
  };

  useEffect(() => {
    document.body.classList.add('body-style');
    const headerElement = document.getElementById('header');
    if (headerElement) {
      headerElement.classList.add('body-style');
    }

    return () => {
      document.body.classList.remove('body-style');
      if (headerElement) {
        headerElement.classList.remove('body-style');
      }
    };
  }, []);



  return (
    <div className="body-game">
      <div className="limited-width-container">
        {Object.keys(userLikes).length > 0 && (
          <div>
          </div>
        )}
        {Object.keys(userFollowers).length > 0 && (
          <div>
          </div>
        )}
        {Object.keys(userGifters).length > 0 && (
          <div>
          </div>
        )}
        {camera === 1 && (
          <div className="cameras">
            <div id="cam1" className="fancycam1 cam">
              {showPopupLikes && (
                <div className="popup-likes-overlay cam1-popup">
                  <div className="popup-likes-content bgTransparent justify-content-center align-items-center">
                    <div className="d-flex justify-content-around align-items-center w-100">
                      <img
                        src={popupData.profilePictureUrl}
                        alt={popupData.nickname}
                        className="popup-likes-image"
                      />
                      <div>
                        <h6>{popupData.nickname}</h6>
                        <p className="fontcolorYellow m-0">{t('like_thanks')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showPopupFollowers && (
                <div className="popup-likes-overlay cam1-popup">
                  <div className="popup-likes-content bgTransparent justify-content-center align-items-center">
                    <div className="d-flex justify-content-around align-items-center w-100">
                      <img
                        src={popupDataFollowers.profilePictureUrl}
                        alt={popupDataFollowers.nickname}
                        className="popup-likes-image"
                      />
                      <div>
                        <h6 className='fontcolorWhite m-0'>{popupDataFollowers.nickname}</h6>
                        <p className="fontcolorYellow m-0">{t('follow_thanks')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showPopupGifters && (
                <div className="popup-likes-overlay cam1-popup">
                  <div className="popup-likes-content bgTransparent justify-content-center align-items-center">
                    <div className="d-flex justify-content-around align-items-center w-100">
                      <img
                        src={popupDataGifters.profilePictureUrl}
                        alt={popupDataGifters.nickname}
                        className="popup-likes-image"
                      />
                      <div>
                        <h6 className='fontcolorWhite m-0'>{popupDataGifters.nickname}</h6>
                        {/* <div className="d-flex align-items-center justify-content-center">
                    <p className="col fontcolorYellow m-0 p-0">{popupDataGifters.giftName}</p>
                    <img
                      src={popupDataGifters.giftPictureUrl}
                      alt={popupDataGifters.nickname}
                      className="popup-gift-image"
                    />
                    <p className="col fontcolorYellow m-0 p-0">{popupDataGifters.repeatCount}</p>
                    </div> */}
                        <p className="fontcolorYellow m-0 text-center">{t('gift_thanks')}
                          {/* <img
                      src={popupDataGifters.giftPictureUrl}
                      alt={popupDataGifters.nickname}
                      className="popup-gift-image"
                    />
                    {popupDataGifters.repeatCount} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {camera === 2 && (
          <div className="cameras">
            <div id="cam2" className="col-12 row cam">
              <div className="col-6 likes">
                <div className="fancycam2">
                  {showPopupLikes && (
                    <div className="popup-likes-overlay cam2-popup">
                      <div className="popup-likes-content bgTransparent">
                        <img
                          src={popupData.profilePictureUrl}
                          alt={popupData.nickname}
                          className="popup-likes-image"
                        />
                        <h6>{popupData.nickname}</h6>
                        <p className="fontcolorYellow m-0">{t('like_thanks')}</p>
                      </div>
                    </div>
                  )}
                  {showPopupFollowers && (
                    <div className="popup-likes-overlay cam1-popup">
                      <div className="popup-likes-content bgTransparent">
                        <img
                          src={popupDataFollowers.profilePictureUrl}
                          alt={popupDataFollowers.nickname}
                          className="popup-likes-image"
                        />
                        <h6>{popupDataFollowers.nickname}</h6>
                        <p className="fontcolorYellow m-0">{t('follow_thanks')}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="fancycam2">
                  {showPopupGifters && (
                    <div className="popup-likes-overlay cam1-popup">
                      <div className="popup-likes-content bgTransparent">
                        <img
                          src={popupDataGifters.profilePictureUrl}
                          alt={popupDataGifters.nickname}
                          className="popup-likes-image"
                        />
                        <h6 className='m-0'>{popupDataGifters.nickname}</h6>
                        {/* <div className="d-flex align-items-center justify-content-center">
                    <p className="col fontcolorYellow m-0 p-0">{popupDataGifters.giftName}</p>
                    <img
                      src={popupDataGifters.giftPictureUrl}
                      alt={popupDataGifters.nickname}
                      className="popup-gift-image"
                    />
                    <p className="col fontcolorYellow m-0 p-0">{popupDataGifters.repeatCount}</p>
                    </div> */}
                        <p className="fontcolorYellow m-0">{t('gift_thanks')}
                          {/* <img
                      src={popupDataGifters.giftPictureUrl}
                      alt={popupDataGifters.nickname}
                      className="popup-gift-image"
                    />
                    {popupDataGifters.repeatCount} */}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="text-center">{children}</div>

        <div>
          <span className="waterName water">❤ InteractiveTok ❤</span>
          <span className="waterName water">❤ InteractiveTok ❤</span>
        </div>

        <div className="d-flex justify-space-around" style={{ position: 'absolute', right: '5px', bottom: '5px', gap: '10px' }}>
          <button
            className="icon-wrapper"
            onClick={() => window.location.reload()}
          >
            <i className="bi bi-arrow-clockwise fs-5 fw-bold fontcolorYellow"></i>
          </button>

          <button
          className="icon-wrapper"
          onClick={() => setIsVotingPopupVisible(true)}
        >
          <i className="bi bi-bar-chart fs-5 fw-bold fontcolorYellow"></i>
        </button>
        </div>

        {isGiftsVisible && (
          <div className="popup mx-auto bg-success rounded-3 px-3 py-2 mb-1">
            <h5 className="fontcolorYellow text-center">{t('top3')}</h5>
            <ul className="fontcolorWhite list-unstyled text-center p-0">
              {topGifters.map((gifter, index) => (
                <li key={index} className="d-flex mb-2">
                  <span className="col-1 align-self-center">{index + 1}.</span>
                  <img src={gifter.profilePictureUrl} alt={gifter.nickname} className="rounded-circle align-self-center" width="45" height="45" />
                  <span className="col-7 align-self-center">{gifter.nickname}</span>
                  <span className="col-2 align-self-center text-danger">
                    <span className='fontcolorYellow'>{gifter.totalGiftCount}</span> &#x1F48E;
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="d-flex justify-space-around" style={{ position: 'absolute', left: '5px', bottom: '5px', gap: '10px' }}>
          <button
            className="icon-wrapper"
            onClick={toggleLikesPopup}
          >
            <i className="bi bi-hearts fs-5 fw-bold fontcolorYellow"></i>
          </button>

          <button
            className="icon-wrapper"
            onClick={toggleGiftsPopup}
          >
            <i className="bi bi-gift fs-5 fw-bold fontcolorYellow"></i>
          </button>
          
          <button
            className="icon-wrapper"
            onClick={toggleVolumePopup}
          >
            <i className="bi bi-gear fs-5 fw-bold fontcolorYellow"></i>
          </button>
        </div>

        {isLikesVisible && (
          <div className="popup mx-auto bg-success rounded-3 px-3 py-2 mb-1">
    <div className="position-relative d-flex align-items-center">
      <button
        className="icon-wrapper"
        onClick={handleResetLikes}
        style={{ position: 'relative', zIndex: 1 }}
      >
        <i className="bi bi-arrow-clockwise fs-5 fw-bold fontcolorYellow"></i>
      </button>
      <h5
        className="fontcolorYellow text-center position-absolute w-100"
        style={{ top: '0', left: '0', zIndex: 0 }}
      >
        {t('top10')}
      </h5>
    </div>
            <ul className="fontcolorWhite list-unstyled text-center p-0">
              {topLikers.map((liker, index) => (
                <li key={index} className="d-flex mb-2">
                  <span className="col-1 align-self-center">{index + 1}.</span>
                  <img src={liker.profilePictureUrl} alt={liker.nickname} className="rounded-circle align-self-center" width="45" height="45" />
                  <span className="col-7 align-self-center">{liker.nickname}</span>
                  <span className="col-2 align-self-center text-danger "><span className='fontcolorYellow'>{liker.totalLikeCount}</span> &hearts;</span>
                </li>
              ))}
            </ul>
          </div>
        )}

{isVotingPopupVisible && (
          <VotingPopup
            onClose={() => setIsVotingPopupVisible(false)}
            userId={ userId } // يمكن تمرير userId الحقيقي هنا
          />
        )}

        {isVolumePopupVisible && (
          <div className="popup mx-auto popup-settings rounded-3 px-3 py-2">
            {/* Existing volume controls */}
            <ColorPicker />
            <div className="mb-1 row">
              <div className="col">
                <label className="form-check-label fontcolorWhite">
                  موسيقى
                </label>
              </div>
              <div className="col">
                <input
                  className="form-check-input"
                  type="radio"
                  name="backgroundAudio"
                  value="backgroundAudio"
                  checked={selectedAudio === 'backgroundAudio'}
                  onChange={handleAudioChange}
                />
              </div>
              <div className="col">
                <input
                  className="form-check-input"
                  type="radio"
                  name="backgroundAudio"
                  value="backgroundAudio2"
                  checked={selectedAudio === 'backgroundAudio2'}
                  onChange={handleAudioChange}
                />
              </div>
              <div className="col">
                <input
                  className="form-check-input"
                  type="radio"
                  name="backgroundAudio"
                  value="backgroundAudio3"
                  checked={selectedAudio === 'backgroundAudio3'}
                  onChange={handleAudioChange}
                />
              </div>
              <div className="col">
                <input
                  className="form-check-input"
                  type="radio"
                  name="backgroundAudio"
                  value="backgroundAudio4"
                  checked={selectedAudio === 'backgroundAudio4'}
                  onChange={handleAudioChange}
                />
              </div>
              <div className="col">
                <input
                  className="form-check-input"
                  type="radio"
                  name="backgroundAudio"
                  value="backgroundAudio5"
                  checked={selectedAudio === 'backgroundAudio5'}
                  onChange={handleAudioChange}
                />
              </div>
              <div className="col">
                <input
                  className="form-check-input"
                  type="radio"
                  name="backgroundAudio"
                  value="backgroundAudio6"
                  checked={selectedAudio === 'backgroundAudio6'}
                  onChange={handleAudioChange}
                />
              </div>
            </div>
            <div className="row align-items-between">
              <label htmlFor="backgroundVolume" className="form-label col-5 col-sm-5 align-middle fontcolorWhite m-0"> {t('volume')} </label>
              <input
                type="range"
                className="px-0 col-7 col-sm-7"
                id="backgroundVolume"
                min="0"
                max="100"
                value={backgroundVolume}
                onChange={handleVolumeChange}
              />
            </div>
            <div className="mt-1 row align-items-between">
              <div className="container text-center">
                <div className="row">
                  <div className="col">
                    <label className="form-check-label fontcolorWhite">
                      {t('camera')}
                    </label>
                  </div>
                  {/* <div className="col d-flex transition-radio" htmlFor="camSwitch1" id="camSwitch1">
                    <p className="mx-1">0</p>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="camera"
                      value="0"
                      checked={camera === 0}
                      onChange={() => setCamera(0)}
                    />
                  </div> */}
                  <div className="col d-flex transition-radio" htmlFor="camSwitch2" id="camSwitch2">
                    <p className="m-1">1</p>
                    <input
                      className="form-check-input checked"
                      type="radio"
                      name="camera"
                      value="1"
                      checked={camera === 1}
                      onChange={() => setCamera(1)}
                    />
                  </div>
                  <div className="col d-flex transition-radio" htmlFor="camSwitch3" id="camSwitch3">
                    <p className="m-1">2</p>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="camera"
                      value="2"
                      checked={camera === 2}
                      onChange={() => setCamera(2)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-between">
              <div className="container text-center">
                <div className="d-flex justify-content-around">
                  <label className="form-check-label fontcolorWhite">
                    {t('display_likes')}
                    <input className='mx-1' type="checkbox" checked={showLikes} onChange={handleLikesChange} />
                  </label>
                  <label className="form-check-label fontcolorWhite">
                    {t('display_followers')}
                    <input className='mx-1' type="checkbox" checked={showFollowers} onChange={handleFollowersChange} />
                  </label>
                  <label className="form-check-label fontcolorWhite">
                    {t('display_gifters')}
                    <input className='mx-1' type="checkbox" checked={showGifters} onChange={handleGiftersChange} />
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameWrapper;
