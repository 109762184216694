import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSound } from '../gameWrappers/SoundContext';
import io from 'socket.io-client';

const Question = () => {
  const { lng, userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'));
  const currentQuestion = parseInt(queryParams.get('currentQuestion')) || 0;
  const timer = parseInt(queryParams.get('timer'));
  const [grid, setGrid] = useState([]);
  const [timeLeft, setTimeLeft] = useState(timer);
  const [differentChar, setDifferentChar] = useState('');
  const [differentIndex, setDifferentIndex] = useState(-1);
  const [correctPosition, setCorrectPosition] = useState('');
  const [correctComment, setCorrectComment] = useState('');
  const [comments, setComments] = useState([]);
  const { playTimer, playSwing } = useSound();
  const socketRef = useRef(null);
  const timerIdRef = useRef(null);
  const { t } = useTranslation();
  const hashtag = queryParams.get('hashtag');
  const isAutoMode = queryParams.get('isAutoMode') === 'true';

  const rows = 14;
  const cols = 14;
  const storageKey = `dif_${userId}`;

  useEffect(() => {
    if (hashtag && correctPosition !== null) {
      const correctComment = hashtag.toString() + correctPosition.toString();
      setCorrectComment(correctComment);
      console.log(correctPosition)
    }
  }, [hashtag, correctPosition]);

  const generateGrid = useCallback(() => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const mainChar = alphabet[Math.floor(Math.random() * alphabet.length)];
    let diffChar;
    do {
      diffChar = alphabet[Math.floor(Math.random() * alphabet.length)];
    } while (diffChar === mainChar);

    const randomIndex = Math.floor(Math.random() * rows * cols);
    setDifferentChar(diffChar);
    setDifferentIndex(randomIndex);

    const columnLetter = String.fromCharCode(65 + (randomIndex % cols));
    const rowNumber = Math.floor(randomIndex / cols) + 1;
    setCorrectPosition(`${columnLetter}${rowNumber}`);

    const newGrid = Array.from({ length: rows * cols }, (_, index) =>
      index === randomIndex ? diffChar : mainChar
    );
    setGrid(newGrid);
    setTimeLeft(timer); // Reset timer on new grid generation
  }, [rows, cols, timer]);

  const handleChat = useCallback((data) => {
    if (
      data &&
      data.state &&
      data.state.roomId &&
      data.data.comment.includes(hashtag)
    ) {
      setComments((prevComments) => [...prevComments, data.data]);
    }
  }, [hashtag]);

  const insertIntoLocalStorage = useCallback(() => {
    // Retrieve the existing data from localStorage
    const existingData = localStorage.getItem(storageKey);
    const parsedData = existingData ? JSON.parse(existingData) : {}; // Handle null or invalid data
    
    // Convert Arabic numbers to English numbers
    const convertArabicToEnglishNumbers = (str) => {
      const arabicToEnglishMap = {
        '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
        '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
      };
      return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
    };
    
    // Filter and process comments
    const commentsData = comments.reduce((acc, comment) => {
      if (!comment.comment.includes(hashtag)) return acc;
      
      // Only keep the first comment for each user
      if (!acc[comment.userId]) {
        let answer = comment.comment.replace(/\n/g, ' ').replace(/\s+/g, ''); // Clean the comment
        answer = convertArabicToEnglishNumbers(answer).toUpperCase(); // Convert numbers and normalize to uppercase
        const normalizedCorrectComment = correctComment.toUpperCase(); // Normalize the correct comment to uppercase
        const isCorrect = normalizedCorrectComment === answer;
  
        acc[comment.userId] = {
          comment: comment.comment,
          userId: comment.userId,
          nickname: comment.nickname,
          profilePictureUrl: comment.profilePictureUrl,
          followRole: comment.followRole,
          answer: isCorrect ? 'true' : 'false',
        };
      }
      return acc;
    }, {});
    
    // Create the new entry for the current question
    const newEntry = {
      userId,
      hashtag,
      grid,
      currentQuestion,
      differentChar,
      correctPosition,
      correctComment,
      comments: commentsData,
    };
    
    // Update the data with the new entry
    parsedData[currentQuestion] = newEntry;
    
    // Save the updated data back to localStorage
    localStorage.setItem(storageKey, JSON.stringify(parsedData));
  }, [
    comments,
    correctPosition,
    differentChar,
    grid,
    hashtag,
    userId,
    storageKey,
    currentQuestion,
    correctComment,
  ]);
  
  const handleQuestionEnd = useCallback(() => {
    insertIntoLocalStorage();
    setTimeout(() => {
      navigate(
        `/${lng}/${userId}/brainchallenge/result?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestion=${currentQuestion}&timer=${timer}&isAutoMode=${isAutoMode}`
      );
    }, 5000);
  }, [hashtag, insertIntoLocalStorage, navigate, timer, totalQuestions, userId, lng, currentQuestion, isAutoMode]);

  useEffect(() => {
    const handleTimeout = () => {
      if (timeLeft > 0) {
        setTimeLeft((prevTime) => prevTime - 1);
      } else if (timeLeft === 0) {
        handleQuestionEnd();
      }
    };

    timerIdRef.current = setInterval(handleTimeout, 1000);
    return () => clearInterval(timerIdRef.current);
  }, [timeLeft, handleQuestionEnd]);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://backend-tok.weblab99.com');

      socketRef.current.on('connect', () => {
        socketRef.current.emit('joinRoom', { userId });
      });

      socketRef.current.on('chat', handleChat);

      return () => {
        if (socketRef.current) {
          socketRef.current.off('chat', handleChat);
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }
  }, [userId, handleChat]);

  useEffect(() => {
    generateGrid();
  }, [generateGrid]);

  useEffect(() => {
    if (timeLeft === 10) {
      playTimer();
    }
  }, [timeLeft, playTimer]);

  useEffect(() => {
    if (timeLeft === timer) {
      playSwing();
    }
  }, [timeLeft, playSwing, timer]);

  return (
    <div className="container mt-3">
      <div className="row justify-content-center">
              <div className="col-4 col-md-4 element-fancy element-dif fw-bold mx-1">
          <span className="numberQues">{t('question')} : {currentQuestion} / {totalQuestions}</span>
        </div>
      <div className="col-1 col-md-1 element-fancy element-dif mx-1">
          <span className="numbertime">{timeLeft}</span>
        </div>
        </div>
      <div className="bgTransparent rounded-4 my-2">
        <h6 className="info">{t('add_hashtag')}</h6>
        <h6 className="info">{t('hashtag_today')} : <span className="fontcolorWhite">{hashtag}</span>, {t('example_q')} : <span className="fontcolorWhite">{hashtag} b4</span> </h6>
      </div>
      <div className="d-flex flex-column align-items-end">
        <div className="d-flex">
          {Array.from({ length: cols }).map((_, index) => (
            <div
              key={`col-${index}`}
              className="d-flex align-items-center justify-content-center fontcolorWhite"
              style={{
                width: '25px',
                height: '25px',
                margin: '1px',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {String.fromCharCode(65 + index)}
            </div>
          ))}
        </div>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <div key={`row-${rowIndex}`} className="d-flex">
            <div
              className="d-flex align-items-center justify-content-center fontcolorWhite"
              style={{
                width: '25px',
                height: '25px',
                margin: '1px',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {rowIndex + 1}
            </div>
            {grid
  .slice(rowIndex * cols, (rowIndex + 1) * cols)
  .map((char, colIndex) => {
    const isDifferentChar = rowIndex * cols + colIndex === differentIndex;
    const isTimeFinished = timeLeft === 0;
    const cellClass = isDifferentChar && isTimeFinished ? 'bg-success' : '';
    
    return (
      <div
        key={`${rowIndex}-${colIndex}`}
        className={`border border-dark d-flex align-items-center justify-content-center fontcolorYellow ${cellClass}`}
        style={{
          width: '25px',
          height: '25px',
          margin: '1px',
          fontWeight: 'bold',
          backgroundColor: isDifferentChar && isTimeFinished ? '#d4edda' : 'white',
        }}
      >
        {char}
      </div>
    );
  })}

          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
