import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18n}>
    <App />
    <button
          className="refresh-button"
          onClick={() => window.location.reload()}
          style={{
            width: '30px',
            height: '30px',
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            background: '#00ffff00',
          }}
        >
          <i className="bi bi-arrow-clockwise fs-5 fw-bold fontcolorYellow"></i>
        </button>
  </I18nextProvider>
);

reportWebVitals();
