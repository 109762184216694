import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { lng } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null); // State to store userId
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const { t } = useTranslation();
  const navigate = useNavigate(); // Use useNavigate for programmatic navigation

  const changeLanguage = (event) => {
    const lng = event.target.value;
    i18n.changeLanguage(lng);
    setLanguage(lng);
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';

    // Update the URL with the selected language
    navigate(`/${lng}`);
  };

  useEffect(() => {
    // Check if token exists in localStorage when component mounts
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token); // Set isAuthenticated to true if token exists, false otherwise

    // Fetch userId from localStorage
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId); // Set userId state if it exists in localStorage
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from local storage
    localStorage.removeItem('userId'); // Remove userId from local storage
    setIsAuthenticated(false); // Update isAuthenticated state
    setUserId(null); // Clear userId state
    window.location.href = `${lng}/login`; // Redirect to login page
  };

  return (
    <div className="header-nav">
      <div className="container bgTransparent p-3 rounded-5">
        <div className="d-flex">
          <nav>
            <select onChange={changeLanguage} value={language} className="px-2 py-2" style={{ backgroundColor: 'transparent', color: 'white' }}>
              <option className="bg-secondary" value="en">English</option>
              <option className="bg-secondary" value="ar">عربي</option>
              {/* <option className="bg-secondary" value="de">Deutsch</option> */}
            </select>
          </nav>
          {!isAuthenticated ? (
            <>
              {/* <div className="mx-1 btn btn-outline-light">
                <Link className="nav-link" to={`/${language}/signup`}>{t('signup')}</Link>
              </div> */}
              <div className="mx-1 btn btn-outline-light">
                <Link className="nav-link" to={`/${language}/login`}>{t('login')}</Link>
              </div>
            </>
          ) : (
            <>
              {userId && (
                <div className="mx-1 btn btn-outline-light">
                  <Link className="nav-link" to={`/${language}/dashboard/${userId}`}>{t('dashboard')}</Link>
                </div>
              )}
              <div className="mx-1 btn btn-outline-light">
                <button className="btn btn-link nav-link" onClick={handleLogout}>{t('logout')}</button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
