// src/components/brainchallenge/ConditionalHeader.js
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../Header';
import GameHeader from './GameHeader';
import './GameWrapper.scss'; // Ensure you import your CSS

const ConditionalHeader = ({ isAuthenticated, onLogout }) => {
  const location = useLocation();
  const { lng } = useParams(); // Get lng from useParams

  const gamePaths = [
    '/dashboard',
    '/Dashboard',
    '/brainchallenge/startgame',
    '/brainchallenge/settings',
    '/brainchallenge/question',
    '/brainchallenge/result',
    '/brainchallenge/leaderboard',
    '/numberchallenge/numstartgame',
    '/numberchallenge/numsettings',
    '/numberchallenge/numround',
    '/numberchallenge/numresult',
    '/numberchallenge/numleaderboard',
    '/memorychallenge/memstartgame',
    '/memorychallenge/memsettings',
    '/memorychallenge/memround',
    '/memorychallenge/memresult',
    '/memorychallenge/memleaderboard',
    '/teamschallenge/teamsstartgame',
    '/teamschallenge/teamssettings',
    '/teamschallenge/teamsquestion',
    '/teamschallenge/teamsresult',
    '/teamschallenge/teamsleaderboard',
    '/brainchallengeupdate/startgameupdate',
    '/brainchallengeupdate/settingsupdate',
    '/brainchallengeupdate/questionupdate',
    '/brainchallengeupdate/resultupdate',
    '/brainchallengeupdate/leaderboardupdate',
    '/millionchallenge/startgamemillion',
    '/millionchallenge/settingsmillion',
    '/millionchallenge/questionmillion',
  ];

  const isGameRoute = gamePaths.some(path => location.pathname.includes(path));

  return (
    <div className={isGameRoute ? '' : ''}>
      {isGameRoute ? (
        <GameHeader lng={lng} />
      ) : (
        <Header isAuthenticated={isAuthenticated} onLogout={onLogout} />
      )}
    </div>
  );
};

export default ConditionalHeader;
