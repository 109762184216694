import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSound } from '../gameWrappers/SoundContext';
import io from 'socket.io-client';

const QuestionUpdate = () => {
  const { lng, userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'));
  const timer = parseInt(queryParams.get('timer'));
  const currentQuestionIndex = parseInt(queryParams.get('currentQuestionIndex')) || 0;
  const hashtag = queryParams.get('hashtag');
  const [questions, setQuestions] = useState([]);
  const [timeLeft, setTimeLeft] = useState(timer);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [comments, setComments] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timerRunning, setTimerRunning] = useState(true);
  const [correctAnswerNum, setCorrectAnswerNum] = useState(null);
  const [correctComment, setCorrectComment] = useState('');
  const level = queryParams.get('level');
  const { playTimer, playSwing } = useSound();
  const socketRef = useRef(null);
  const timerIdRef = useRef(null);
  const { t } = useTranslation();
  const isAutoMode = queryParams.get('isAutoMode') === 'true';


  const getCorrectAnswerNum = (question) => {
    if (!question || !question.correct) return null;
    if (question.correct === question.first) return 1;
    if (question.correct === question.second) return 2;
    if (question.correct === question.third) return 3;
    if (question.correct === question.fourth) return 4;
    return null;
  };
  

  useEffect(() => {
    if (hashtag && correctAnswerNum !== null) {
      const correctComment = hashtag.toString() + correctAnswerNum.toString();
      setCorrectComment(correctComment);
      console.log(correctAnswerNum)
    }
  }, [hashtag, correctAnswerNum]);

  
  
  useEffect(() => {
    if (questions.length > 0 && currentQuestionIndex >= 0) {
      setCorrectAnswerNum(getCorrectAnswerNum(questions[currentQuestionIndex]));
    }
  }, [questions, currentQuestionIndex]);
  

  
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        // console.log('Fetching questions for level:', level);
  
        const levels = {
          firstlevelUp: 'firstlevelUp',
          secondlevelUp: 'secondlevelUp',
          thirdlevelUp: 'thirdlevelUp',
          alllevels: ['firstlevelUp', 'secondlevelUp', 'thirdlevelUp'],
        };
  
        let currentLevelQuestions = {};
  
        // Fetch questions based on the level
        if (level === 'alllevels') {
          levels.alllevels.forEach((key) => {
            const levelQuestions = JSON.parse(localStorage.getItem(key) || '{}');
            currentLevelQuestions = { ...currentLevelQuestions, ...levelQuestions };
          });

        } else {
          const key = levels[level];
          currentLevelQuestions = JSON.parse(localStorage.getItem(key) || '{}');

        }
  
        // Convert the object to an array
        const questionArray = Object.values(currentLevelQuestions);
  
        if (!questionArray || questionArray.length === 0) {
          console.error(`No questions available for level: ${level}`);
          setQuestions([]);
          setLoading(false);
          return;
        }
  
        // Shuffle questions and limit to totalQuestions
        const shuffledQuestions = questionArray.sort(() => Math.random() - 0.5);
        const sessionQuestions = shuffledQuestions.slice(
          0,
          Math.min(totalQuestions, questionArray.length)
        );
  
        if (sessionQuestions.length < totalQuestions) {
          console.warn(
            `totalQuestions (${totalQuestions}) exceeds available questions (${questionArray.length}). Adjusting.`
          );
        }
  
        // console.log('Selected session questions:', sessionQuestions);
        setQuestions(sessionQuestions);
  
        // Handle current question's removal from localStorage
        const currentQuestionIndexObj = sessionQuestions[currentQuestionIndex];
        if (currentQuestionIndexObj) {
          const { id, question } = currentQuestionIndexObj;
  
          if (level === 'alllevels') {
            levels.alllevels.forEach((key) => {
              const levelQuestions = JSON.parse(localStorage.getItem(key) || '{}');
              const updatedLevelQuestions = Object.fromEntries(
                Object.entries(levelQuestions).filter(
                  ([, value]) => !(value.id === id && value.question === question)
                )
              );
              // console.log(`Remaining questions for ${key}:`, updatedLevelQuestions);
              localStorage.setItem(key, JSON.stringify(updatedLevelQuestions));
            });
          } else {
            const key = levels[level];
            const updatedLevelQuestions = Object.fromEntries(
              Object.entries(currentLevelQuestions).filter(
                ([, value]) => !(value.id === id && value.question === question)
              )
            );
            // console.log(`Remaining questions for ${key}:`, updatedLevelQuestions);
            localStorage.setItem(key, JSON.stringify(updatedLevelQuestions));
          }
        }
  
        setLoading(false);
      } catch (error) {
        console.error('Error fetching questions:', error);
        setLoading(false);
      }
    };
  
    fetchQuestions();
  }, [level, totalQuestions, currentQuestionIndex]);
  
  
  

  const insertIntoLocalStorage = useCallback(() => {
    const currentQuestionIndexData = questions[currentQuestionIndex];
    if (!currentQuestionIndexData) return;
  
    const storageKey = `quiz_${userId}`;
    const storedData = JSON.parse(localStorage.getItem(storageKey) || '{}');
  
    // Filter comments to include only the first hashtag-containing comment per user
    let commentsData = {};
    if (comments.length > 0) {
      const filteredCommentsByUser = comments.reduce((acc, comment) => {
        if (!comment.comment.includes(hashtag)) return acc;
        if (!acc[comment.userId]) acc[comment.userId] = comment;
        return acc;
      }, {});
  
      commentsData = Object.values(filteredCommentsByUser).reduce((acc, comment, index) => {
        const key = `comment${index + 1}`;
  
        let answer = comment.comment.replace(/\n/g, ' ').replace(/\s+/g, '');
        const convertArabicToEnglishNumbers = (str) => {
          const arabicToEnglishMap = {
            '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
            '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
          };
          return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
        };
  
        answer = convertArabicToEnglishNumbers(answer);
        const isCorrect = correctComment === answer;
  
        acc[key] = {
          comment: comment.comment,
          userId: comment.userId,
          nickname: comment.nickname,
          profilePictureUrl: comment.profilePictureUrl,
          followRole: comment.followRole,
          answer: isCorrect ? 'true' : 'false',
        };
        return acc;
      }, {});
    }
  
    const newEntry = {
      userId,
      quesId: currentQuestionIndexData.id,
      hashtag,
      totalQuestions,
      timer,
      question: currentQuestionIndexData.question,
      currentQuestionIndex,
      correctAnswer: currentQuestionIndexData.correct,
      correctAnswerNum,
      correctComment,
      comments: commentsData,
    };
  
    storedData[currentQuestionIndexData.id] = newEntry;
  
    localStorage.setItem(storageKey, JSON.stringify(storedData));
  }, [comments, correctAnswerNum, correctComment, currentQuestionIndex, hashtag, questions, timer, totalQuestions, userId]);

  const handleQuestionEnd = useCallback(() => {
    if (questions.length === 0 || !questions[currentQuestionIndex]) {
      console.error('Current question data is undefined');
      return;
    }

    insertIntoLocalStorage();

    setTimeout(() => {
      navigate(
        `/${lng}/${userId}/brainchallengeupdate/resultupdate?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestionIndex=${currentQuestionIndex}&timer=${timer}&level=${level}&isAutoMode=${isAutoMode}`,
        { state: { comments: filteredComments } }
      );
    }, 3000);
  }, [currentQuestionIndex, filteredComments, hashtag, insertIntoLocalStorage, navigate, questions, timer, totalQuestions, userId, level, lng, isAutoMode]);

  useEffect(() => {
    const handleTimeout = () => {
      if (timeLeft > 0 && timerRunning) {
        setTimeLeft((prevTime) => prevTime - 1);
      } else if (timeLeft === 0 && timerRunning) {
        setTimerRunning(false);
        setTimeout(() => {
          handleQuestionEnd();
        }, 1000);
      }
    };

    timerIdRef.current = setInterval(handleTimeout, 1000);
    return () => clearInterval(timerIdRef.current);
  }, [timeLeft, timerRunning, handleQuestionEnd]);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://backend-tok.weblab99.com');

      socketRef.current.on('connect', () => {
        socketRef.current.emit('joinRoom', { userId });
      });

      const handleChat = (data) => {
        if (data && data.state && data.state.roomId && data.data.comment.includes(hashtag)) {
          setComments((prevComments) => [...prevComments, data.data]);
        }
      };

      socketRef.current.on('chat', handleChat);

      return () => {
        if (socketRef.current) {
          socketRef.current.off('chat', handleChat);
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }
  }, [userId, hashtag]);

  useEffect(() => {
    const regex = new RegExp(`^${hashtag}\\s?(\\d|[١٢٣٤])$`, 'i');
    const matchedComments = comments.filter((comment) => regex.test(comment.comment));
    const enhancedComments = matchedComments.map((comment) => {
      const match = comment.comment.match(regex);
      if (match) {
        const answerNumber = match[1];
        let answer = '';
        if (answerNumber === '1' || answerNumber === '١') answer = questions[currentQuestionIndex].first;
        if (answerNumber === '2' || answerNumber === '٢') answer = questions[currentQuestionIndex].second;
        if (answerNumber === '3' || answerNumber === '٣') answer = questions[currentQuestionIndex].third;
        if (answerNumber === '4' || answerNumber === '٤') answer = questions[currentQuestionIndex].fourth;
        return { ...comment, answer };
      }
      return comment;
    });
    setFilteredComments(enhancedComments);
  }, [comments, hashtag, questions, currentQuestionIndex]);

  useEffect(() => {
    setTimeLeft(timer);
    setShowCorrectAnswer(false);
    setComments([]);
    setFilteredComments([]);
    setTimerRunning(true);
  }, [currentQuestionIndex, timer]);

  useEffect(() => {
    if (timeLeft === 10) {
      playTimer();
    }
  }, [timeLeft, playTimer]);

  useEffect(() => {
    if (timeLeft === timer - 1) {
      playSwing();
    }
  }, [timeLeft, playSwing, timer]);

  useEffect(() => {
    if (timeLeft < 1) {
      setShowCorrectAnswer(true);
    }
  }, [timeLeft]);

  if (loading) return <p>{t('loading')}</p>;
  if (questions.length === 0) return <p>No questions available.</p>;
  if (!questions[currentQuestionIndex]) return <p>Loading question...</p>;

  return (
    <div className="container mt-2 rtl">
      <div>
        <div className="fancy ques" id="ques">
          {lng === 'en' ? (
            <h5 className="quesText">{questions[currentQuestionIndex].question}</h5>
          ) : (
            <h4 className="quesText">{questions[currentQuestionIndex].question}</h4>
          )}
        </div>
        <div className="fancy quesnum fw-bold">
          <span className="numberQues">{t('question')} : {currentQuestionIndex + 1} / {totalQuestions}</span>
        </div>
        <div className="fancy questime">
          <span className="numbertime">{timeLeft}</span>
        </div>
      </div>
      <div className="bgTransparent rounded-4 mt-4 mb-2">
        <h6 className="info">{t('add_hashtag')}</h6>
        <h6 className="info">{t('hashtag_today')} : <span className="fontcolorWhite">{hashtag}</span>, {t('example_q')} : <span className="fontcolorWhite">{hashtag} 1</span> </h6>
      </div>
      <div className="list-group">
        <ul className="p-0">
          <li className={`answer fade-in ${showCorrectAnswer && correctAnswerNum === 1 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className="answerSpan fontcolorWhite">1<span className="fontcolorYellow mx-3">|</span>{questions[currentQuestionIndex].first}</h5>
          </li>
          <li className={`answer fade-out ${showCorrectAnswer && correctAnswerNum === 2 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className="answerSpan fontcolorWhite">2<span className="fontcolorYellow mx-3">|</span>{questions[currentQuestionIndex].second}</h5>
          </li>
          <li className={`answer fade-in ${showCorrectAnswer && correctAnswerNum === 3 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className="answerSpan fontcolorWhite">3<span className="fontcolorYellow mx-3">|</span>{questions[currentQuestionIndex].third}</h5>
          </li>
          <li className={`answer fade-out ${showCorrectAnswer && correctAnswerNum === 4 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className="answerSpan fontcolorWhite">4<span className="fontcolorYellow mx-3">|</span>{questions[currentQuestionIndex].fourth}</h5>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default QuestionUpdate;
