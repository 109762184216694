import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Signup from './components/Signup';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AdminDashboard from './components/AdminDashboard';
import ConditionalHeader from './components/gameWrappers/ConditionalHeader';
import GameWrapper from './components/gameWrappers/GameWrapper';
import StartGame from './components/brainchallenge/StartGame';
import Settings from './components/brainchallenge/Settings';
import Question from './components/brainchallenge/Question';
import Result from './components/brainchallenge/Result';
import Leaderboard from './components/brainchallenge/Leaderboard';
import TikTokComments from './components/TikTokComments';
import { CameraProvider } from './components/gameWrappers/CameraContext';
import { SoundProvider } from './components/gameWrappers/SoundContext';
// import NumGameWrapper from './components/numberChallenge/NumGameWrapper';
import NumStartGame from './components/numberChallenge/NumStartGame';
import NumSettings from './components/numberChallenge/NumSettings';
import NumRound from './components/numberChallenge/NumRound';
import NumResult from './components/numberChallenge/NumResult';
import NumLeaderboard from './components/numberChallenge/NumLeaderboard';
// import MemGameWrapper from './components/memoryChallenge/MemGameWrapper';
import MemStartGame from './components/memoryChallenge/MemStartGame';
import MemSettings from './components/memoryChallenge/MemSettings';
import MemRound from './components/memoryChallenge/MemRound';
import MemResult from './components/memoryChallenge/MemResult';
import MemLeaderboard from './components/memoryChallenge/MemLeaderboard';
// import TeamsGameWrapper from './components/teamsChallenge/TeamsGameWrapper';
import TeamsGameColumn from './components/teamsChallenge/TeamsGameColumn';
import TeamsStartGame from './components/teamsChallenge/TeamsStartGame';
import TeamsSettings from './components/teamsChallenge/TeamsSettings';
import TeamsQuestion from './components/teamsChallenge/TeamsQuestion';
import TeamsResult from './components/teamsChallenge/TeamsResult';
import TeamsLeaderboard from './components/teamsChallenge/TeamsLeaderboard';
import { MembersProvider } from './components/teamsChallenge/MembersContext';
// import GameWrapperUpdate from './components/brainchallengeUpdate/GameWrapperUpdate';
import StartGameUpdate from './components/brainchallengeUpdate/StartGameUpdate';
import SettingsUpdate from './components/brainchallengeUpdate/SettingsUpdate';
import QuestionUpdate from './components/brainchallengeUpdate/QuestionUpdate';
import ResultUpdate from './components/brainchallengeUpdate/ResultUpdate';
import LeaderboardUpdate from './components/brainchallengeUpdate/LeaderboardUpdate';
// import GameWrapperMillion from './components/millionChallenge/GameWrapperMillion';
import StartGameMillion from './components/millionChallenge/StartGameMillion';
import SettingsMillion from './components/millionChallenge/SettingsMillion';
import QuestionMillion from './components/millionChallenge/QuestionMillion';
// import ResultMillion from './components/millionChallenge/ResultMillion';
// import LeaderboardMillion from './components/millionChallenge/LeaderboardMillion';
import { useTranslation } from 'react-i18next';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <CameraProvider>
        <SoundProvider>
          <div>
            <ConditionalHeader isAuthenticated={isAuthenticated} onLogout={handleLogout} />
            <Routes>
              <Route path="/" element={<Navigate to="/ar" />} /> {/* Redirect to Arabic by default */}
              <Route path="/:lng/*" element={<MainContent isAuthenticated={isAuthenticated} onLogin={handleLogin} />} />
            </Routes>
          </div>
        </SoundProvider>
      </CameraProvider>
    </Router>
  );
};

const MainContent = ({ isAuthenticated, onLogin }) => {
  const { lng } = useParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    // Set the language using i18n
    i18n.changeLanguage(lng);
    
    // Set the document direction based on the language
    document.body.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
  }, [lng, i18n]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login onLogin={onLogin} />} />
      <Route path="/dashboard/:userId" element={isAuthenticated ? <GameWrapper><Dashboard /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/AdminDashboard" element={isAuthenticated ? <AdminDashboard /> : <Login onLogin={onLogin} />} />

      {/* Brain Challenge Routes */}
      <Route path="/:userId/brainchallenge/startgame" element={isAuthenticated ? <GameWrapper><StartGame /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/brainchallenge/settings" element={isAuthenticated ? <GameWrapper><Settings /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/brainchallenge/question" element={isAuthenticated ? <GameWrapper><Question /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/brainchallenge/result" element={isAuthenticated ? <GameWrapper><Result /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/brainchallenge/leaderboard" element={isAuthenticated ? <GameWrapper><Leaderboard /></GameWrapper> : <Login onLogin={onLogin} />} />

      {/* Number Challenge Routes */}
      <Route path="/:userId/numberchallenge/numstartgame" element={isAuthenticated ? <GameWrapper><NumStartGame /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/numberchallenge/numsettings" element={isAuthenticated ? <GameWrapper><NumSettings /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/numberchallenge/numround" element={isAuthenticated ? <GameWrapper><NumRound /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/numberchallenge/numresult" element={isAuthenticated ? <GameWrapper><NumResult /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/numberchallenge/numleaderboard" element={isAuthenticated ? <GameWrapper><NumLeaderboard /></GameWrapper> : <Login onLogin={onLogin} />} />

      {/* Memory Challenge Routes */}
      <Route path="/:userId/memorychallenge/memstartgame" element={isAuthenticated ? <GameWrapper><MemStartGame /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/memorychallenge/memsettings" element={isAuthenticated ? <GameWrapper><MemSettings /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/memorychallenge/memround" element={isAuthenticated ? <GameWrapper><MemRound /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/memorychallenge/memresult" element={isAuthenticated ? <GameWrapper><MemResult /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/memorychallenge/memleaderboard" element={isAuthenticated ? <GameWrapper><MemLeaderboard /></GameWrapper> : <Login onLogin={onLogin} />} />

      {/* Teams Challenge Routes */}

      <Route path="/:userId/teamschallenge/teamsstartgame" element={isAuthenticated ? <GameWrapper><TeamsStartGame /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/teamschallenge/teamssettings" element={isAuthenticated ? <MembersProvider><TeamsGameColumn><GameWrapper><TeamsSettings /></GameWrapper></TeamsGameColumn></MembersProvider> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/teamschallenge/teamsquestion" element={isAuthenticated ? <MembersProvider><TeamsGameColumn><GameWrapper><TeamsQuestion /></GameWrapper></TeamsGameColumn></MembersProvider> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/teamschallenge/teamsresult" element={isAuthenticated ? <MembersProvider><TeamsGameColumn><GameWrapper><TeamsResult /></GameWrapper></TeamsGameColumn></MembersProvider> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/teamschallenge/teamsleaderboard" element={isAuthenticated ? <MembersProvider><TeamsGameColumn><GameWrapper><TeamsLeaderboard /></GameWrapper></TeamsGameColumn></MembersProvider> : <Login onLogin={onLogin} />} />

      {/* Brain Challenge Update Routes */}
      <Route path="/:userId/brainchallengeupdate/startgameupdate" element={isAuthenticated ? <GameWrapper><StartGameUpdate /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/brainchallengeupdate/settingsupdate" element={isAuthenticated ? <GameWrapper><SettingsUpdate /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/brainchallengeupdate/questionupdate" element={isAuthenticated ? <GameWrapper><QuestionUpdate /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/brainchallengeupdate/resultupdate" element={isAuthenticated ? <GameWrapper><ResultUpdate /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/brainchallengeupdate/leaderboardupdate" element={isAuthenticated ? <GameWrapper><LeaderboardUpdate /></GameWrapper> : <Login onLogin={onLogin} />} />
      
      {/* TikTok Challenge */}
      <Route path="/tiktok-comments" element={<TikTokComments />} />

      {/* Brain Challenge Million Routes */}
      <Route path="/:userId/millionChallenge/startgamemillion" element={isAuthenticated ? <GameWrapper><StartGameMillion /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/millionChallenge/settingsmillion" element={isAuthenticated ? <GameWrapper><SettingsMillion /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/millionChallenge/questionmillion" element={isAuthenticated ? <GameWrapper><QuestionMillion /></GameWrapper> : <Login onLogin={onLogin} />} />
      {/* <Route path="/:userId/millionChallenge/resultmillion" element={isAuthenticated ? <GameWrapper><ResultMillion /></GameWrapper> : <Login onLogin={onLogin} />} />
      <Route path="/:userId/millionChallenge/leaderboardmillion" element={isAuthenticated ? <GameWrapper><LeaderboardMillion /></GameWrapper> : <Login onLogin={onLogin} />} /> */}
    </Routes>
  );
};

export default App;
