import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const MemResult = () => {
  const { lng, userId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentQuestion = parseInt(queryParams.get('currentRound'), 10);
  const [questionData, setQuestionData] = useState(null);
  const [correctFollowers, setCorrectFollowers] = useState([]);
  const [wrongFollowers, setWrongFollowers] = useState([]);
  const [nonFollowers, setNonFollowers] = useState([]);
  const [activeTab, setActiveTab] = useState('correctFollowers');
  const navigate = useNavigate();
  const totalQuestions = parseInt(queryParams.get('totalRounds'), 10) || 0;
  const timer = parseInt(queryParams.get('timer'), 10) || 0;
  const timer_answer = parseInt(queryParams.get('timer_answer'), 10);
  const hashtag = queryParams.get('hashtag') || '';
  const totalShapes = parseInt(queryParams.get('totalShapes'), 10) || 20;
  const { t } = useTranslation();

  // Fetch question data and TikTok comments
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backend-tok.weblab99.com/getTikComments', {
          params: { userId, totalQuestions, currentQuestion }
        });
        const data = response.data;
        setQuestionData(data.questionData);

        const tikData = data.tikData;
        const correctFollowers = [];
        const wrongFollowers = [];
        const nonFollowers = [];

        tikData.forEach(comment => {
          if (comment.answer === 'true' && (comment.followRole === 1 || comment.followRole === 2)) {
            correctFollowers.push(comment);
          } else if (comment.answer === 'false' && (comment.followRole === 1 || comment.followRole === 2)) {
            wrongFollowers.push(comment);
          } else if (comment.followRole === 0) {
            nonFollowers.push(comment);
          }
        });

        setCorrectFollowers(correctFollowers);
        setWrongFollowers(wrongFollowers);
        setNonFollowers(nonFollowers);

        // console.log('Data retrieved from tik_comments successfully.', data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId, totalQuestions, currentQuestion]);

  // Handle inserting correct followers
  useEffect(() => {
    const insertCorrectFollowers = async () => {
      try {
        if (correctFollowers.length > 0) {
          await axios.post('https://backend-tok.weblab99.com/insertWinners', {
            userId,
            totalQuestions,
            currentQuestion,
            correctFollowers
          });
          // console.log('Correct followers inserted successfully.', correctFollowers);
        }
      } catch (error) {
        console.error('Error inserting correct followers:', error);
      }
    };

    if (correctFollowers.length > 0) {
      insertCorrectFollowers();
    }
  }, [userId, totalQuestions, currentQuestion, correctFollowers]);

  const handleNext = () => {
    if (currentQuestion < totalQuestions) {
      navigate(`/${lng}/${userId}/memorychallenge/memround?hashtag=${hashtag}&totalRounds=${totalQuestions}&timer=${timer}&timer_answer=${timer_answer}&currentRound=${currentQuestion + 1}&totalShapes=${totalShapes}`);
    } else {
      navigate(`/${lng}/${userId}/memorychallenge/memleaderboard`);
    }
  };

  const renderComments = (comments) => {
    return (
      <div className="tab-content mt-2 custom-scrollbar slow-motion" style={{ maxHeight: '355px', overflowY: 'auto' }}>
        <div className="container mb-2">
          <div className="row">
          {comments.map((comment, index) => (
              <div key={index} className="col-4 col-sm-4 col-md-4">
                <div className="text-center mb-2">
                  <img
                    src={comment.profilePictureUrl}
                    alt="Profile"
                    className="rounded-circle mb-2"
                    width="60"
                  />
                  <h6>{comment.nickname}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  
  // useEffect(() => {
  //   // Refresh page after 2 seconds if no question data
  //   if (!questionData) {
  //     const timeoutId = setTimeout(() => {
  //       window.location.reload();
  //     }, 2000);

  //     return () => clearTimeout(timeoutId); // Clean up timeout if component unmounts
  //   }
  // }, [questionData]);

  if (!questionData) return (
    <div>
      <p>{t('error_occurred')}</p>
      <button className="col-5 col-sm-5 col-md-5 fancy button" onClick={handleNext} style={{ width: 'auto' }}>
        <h6 className="fontcolorYellow" style={{ position: 'relative', zIndex: '1', lineHeight: 'revert' }}>
          {currentQuestion < totalQuestions ? t('next') : t('leaderboard')}
        </h6>
      </button>
    </div>
  );

  return (
    <div className="container mt-2">
      <h4 className="numhide">{t('round')} {currentQuestion} / {totalQuestions}</h4>
            <div className="mb-2">
      </div>
      <h6 className="quesText mb-3">{questionData.question}</h6>
      <div className="row justify-content-center mb-3 p-2 bgTransparent rounded-3">

      <button className="col-5 col-sm-5 col-md-5  fancy button align-self-center" onClick={handleNext} style={{width: '120px'}}>
          <h6 className="fontcolorYellow" style={{ position: 'relative', zIndex: '1', lineHeight: 'revert'}}>{currentQuestion < totalQuestions ? t('next_question') : t('leaderboard')}</h6>
        </button>
      </div>
<div className="mb-3 bgTransparent rounded-3">
      <ul className="p-0 nav nav-tabs justify-content-around ">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'correctFollowers' ? 'active' : ''}`}
            onClick={() => setActiveTab('correctFollowers')}
          >
            {t('correct')}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'wrongFollowers' ? 'active' : ''}`}
            onClick={() => setActiveTab('wrongFollowers')}
          >
            {t('incorrect')}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'nonFollowers' ? 'active' : ''}`}
            onClick={() => setActiveTab('nonFollowers')}
          >
            {t('not_following')}
          </button>
        </li>
      </ul>

      <div className="tab-content mt-2">
        {activeTab === 'correctFollowers' && renderComments(correctFollowers)}
        {activeTab === 'wrongFollowers' && renderComments(wrongFollowers)}
        {activeTab === 'nonFollowers' && renderComments(nonFollowers)}
      </div>
</div>

    </div>
  );
};

export default MemResult;
