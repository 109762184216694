import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const MemStartGame = () => {
  const { lng, userId } = useParams();
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`https://backend-tok.weblab99.com/users/${userId}`);
        setUserData(res.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, [userId]);

  return (
    <div className="container mt-5">
      <h2 className="mb-4 fontcolorYellow fw-bold">{t('start_challenge')}</h2>
      {userData ? (
        <>
          <h3 className="fontcolorWhite">{t('with_streamer')}: <span className="fontcolorYellow">{userData.username}</span></h3>
          <div className="games text-center mt-5">
            <div className="row justify-content-around p-2">
                <Link
                  to={`/${lng}/${userId}/memorychallenge/memsettings`}
                  state={{ username: userData.username }}
                  className="card col-md-auto bgTransparent fontcolorWhite"
                  style={{ width: '18rem', textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="card-body">
                    <h5 className="card-title fontcolorYellow fw-bold">{t('memory_challenge')}</h5>
                    <p className="card-text">
                      {t('memory_challenge_desc')}
                      <br></br>
                      {t('competitive_game')}
                      <br />
                      {t('focus')}
                    </p>
                    <h6 className="fontcolorYellow">{t('comment_memory')}</h6>
                    <p className="fw-bold">
                      {t('example')}:
                      <span className="fontcolorYellow"> 1, 2, 3, 4, 5 </span>
                    </p>
                  </div>
                </Link>
            </div>
          </div>
        </>
      ) : (
        <p>{t('loading')}</p>
      )}
    </div>
  );
};

export default MemStartGame;
