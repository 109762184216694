import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

const VotingPopup = ({ onClose, userId }) => {
    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState([]);
    const [newOption, setNewOption] = useState('');
    const [votes, setVotes] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [collectionFinished, setCollectionFinished] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(30);
    const [votingStarted, setVotingStarted] = useState(false); // للتحكم في عرض واجهة التصويت
    const socketRef = useRef(null);

    // تهيئة الـ Socket
    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io('https://backend-tok.weblab99.com');
    
            socketRef.current.on('connect', () => {
                socketRef.current.emit('joinRoom', { userId });
            });
    
            const handleChat = (data) => {
                if (timeRemaining > 0 && data && data.data && votingStarted) {
                    const comment = data.data.comment.replace(/\s+/g, '').toLowerCase();
                    const voter = {
                        userId: data.data.userId,
                        nickname: data.data.nickname,
                        profilePictureUrl: data.data.profilePictureUrl,
                    };
    
                    // البحث عن رقم الإجابة
                    const match = comment.match(/(\d)/); // البحث عن أرقام
                    if (match) {
                        const optionIndex = parseInt(match[1], 10) - 1;
                        if (options[optionIndex]) {
                            setVotes((prevVotes) => {
                                const updatedVotes = { ...prevVotes };
                                if (!updatedVotes[optionIndex]) {
                                    updatedVotes[optionIndex] = [];
                                }
    
                                // منع التصويت المكرر
                                const alreadyVoted = Object.values(updatedVotes).flat().find((v) => v.userId === voter.userId);
                                if (!alreadyVoted) {
                                    updatedVotes[optionIndex].push(voter);
                                }
    
                                return updatedVotes;
                            });
                        }
                    }
                }
            };
    
            socketRef.current.on('chat', handleChat);
    
            return () => {
                if (socketRef.current) {
                    socketRef.current.off('chat', handleChat);
                    socketRef.current.disconnect();
                    socketRef.current = null;
                }
            };
        }
    }, [options, votingStarted, userId, timeRemaining]);
    

    // العد التنازلي
    useEffect(() => {
        if (votingStarted && timeRemaining > 0) {
            const timer = setInterval(() => {
                setTimeRemaining((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(timer);
        } else if (timeRemaining === 0) {
            setCollectionFinished(true);
        }
    }, [timeRemaining, votingStarted]);

    const handleAddOption = () => {
        if (newOption.trim() !== '') {
            setOptions([...options, newOption]);
            setNewOption('');
        }
    };

    const handleStartVoting = () => {
        if (question.trim() && options.length > 0) {
            setVotingStarted(true);
            setVotes({});
            setTimeRemaining(30); // Reset timer
            setCollectionFinished(false);
        }
    };

    return (
        <div className="popup-overlay">
            <div className="ask_audience p-2 d-flex flex-column">
                {!votingStarted ? (
                    <>
                        <h5 className="mb-3 text-center">انشئ تصويت</h5>
                        <div className="mb-3">
                            <label className="form-label fontcolorWhite">السؤال</label>
                            <input
                                type="text"
                                className="form-control"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                placeholder="اكتب سؤال"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label fontcolorWhite">الخيارات</label>
                            <div className="d-flex mb-2">
                                <input
                                    type="text"
                                    className="form-control mx-2"
                                    value={newOption}
                                    onChange={(e) => setNewOption(e.target.value)}
                                    placeholder="اضف خيار"
                                />
                                <button className="btn btn-primary" onClick={handleAddOption}>
                                    اضف
                                </button>
                            </div>
                            <ul className="list-group p-0">
                                {options.map((option, index) => (
                                    <li key={index} className="list-group-item fontcolorYellow fw-bold">
                                        {index + 1}. {option}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-danger" onClick={onClose}>
                                إغلاق
                            </button>
                            <button className="btn btn-success" onClick={handleStartVoting}>
                                بدئ التصويت
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                    <div className="d-flex justify-content-between px-2">
                        <h5 className="border-bottom mb-1 pb-2">{question}</h5>
                        <button className="icon-wrapper" onClick={onClose}>
                        <i className="bi bi-x fs-5 fw-bold fontcolorYellow"></i>
                        </button>
                        </div>
                        <div className="d-flex justify-content-between px-2">
                        <h6 className="col fontcolorWhite">قم بالتصويت عبر كتابة الارقام فقط <span className="fontcolorYellow">1,2,3</span>  الخ...</h6>
                        <h6 className="col-1"> {timeRemaining}</h6>
                        </div>
                        
                        <ul className="list-group p-0">
                            {options.map((option, index) => (
                                <li key={index} className="list-group-item">
                                    <div className="d-flex justify-content-between">
                                        <span>{index + 1}. {option}</span>
                                        <span className="fontcolorYellow fw-bold">{votes[index]?.length || 0} صوت</span>
                                    </div>
                                    {/* عرض أسماء وصور المصوتين */}
                                    <div className="d-flex">
                                        {votes[index]?.map((voter) => (
                                            <div key={voter.userId} className="col-3 text-center m-1">
                                                <img
                                                    src={voter.profilePictureUrl || 'default-avatar.png'}
                                                    alt={voter.nickname}
                                                    className="rounded-circle"
                                                    style={{ width: '40px', height: '40px' }}
                                                />
                                                <p className="fontcolorYellow m-0" style={{ fontSize: '0.8rem' }}>{voter.nickname}</p>
                                            </div>
                                        ))}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
        </div>
    );
};

export default VotingPopup;
