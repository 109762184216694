import React, { createContext, useState, useContext } from 'react';

const CameraContext = createContext();

export const CameraProvider = ({ children }) => {
  const [camera, setCamera] = useState(1);

  return (
    <CameraContext.Provider value={{ camera, setCamera }}>
      {children}
    </CameraContext.Provider>
  );
};

export const useCamera = () => {
  return useContext(CameraContext);
};
