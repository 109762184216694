import React, { createContext, useState, useContext, useEffect } from 'react';

const MembersContext = createContext();

export const MembersProvider = ({ children }) => {
  const [members, setMembers] = useState(() => {
    const storedMembers = JSON.parse(localStorage.getItem('members')) || {};
    return storedMembers;
  });

  const [teamLeaders, setTeamLeaders] = useState({ team1: null, team2: null });

  useEffect(() => {
    localStorage.setItem('members', JSON.stringify(members));
  }, [members]);

  return (
    <MembersContext.Provider value={{ members, setMembers, teamLeaders, setTeamLeaders }}>
      {children}
    </MembersContext.Provider>
  );
};

export const useMembers = () => useContext(MembersContext);
