import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
// console.log(username, password, email)
  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend-tok.weblab99.com/signup', { username, email, password });
      alert('User registered successfully');
      window.location.href = '/login';
    } catch (error) {
      console.error(error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="container" style={{ marginTop: '7rem'}}>
      <div className="col-10 col-sm-6 col-md-4 mx-auto">
        <h2 className="my-4 fontWhite">{t('signup')}</h2>
        <form onSubmit={handleSignup}>
          <div className="form-group mb-3">
            <label className='fontWhite'>{t('username')}</label>
            <input 
              type="text" 
              className="form-control" 
              placeholder={t('username_placeholder_r')} 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              required 
            />
            <small className="fontWhite">{t('username_text')}<br/>{t('username_text2')}</small>
          </div>
          <div className="form-group mb-3">
            <label className='fontWhite'>{t('email')}</label>
            <input 
              type="email" 
              className="form-control" 
              placeholder={t('email_placeholder')} 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group mb-3">
            <label className='fontWhite'>{t('password_r')}</label>
            <input 
              type="password" 
              className="form-control" 
              placeholder={t('password_placeholder_r')} 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          <button type="submit" className="btn btn-primary mt-3">{t('signup')}</button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
