import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase'; // Import Firestore
import { useMembers } from './MembersContext';

const TeamsSettings = () => {
  const { lng, userId } = useParams();
  const team1 = 'ازرق';
  const team2 = 'احمر';
  const [userData, setUserData] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(15);
  const [timer, setTimer] = useState(40);
  const [hashtag, setHashtag] = useState('');
  const [level, setLevel] = useState(lng === 'en' ? 'secondlevel_en' : 'secondlevel');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setMembers } = useMembers();

  // Clear members state and local storage when the component mounts
  useEffect(() => {
    localStorage.removeItem('members');
    setMembers({});
  }, [setMembers]);

  useEffect(() => {
    setHashtag(t('hashtag_placeholder'));
  }, [t]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`https://backend-tok.weblab99.com/users/${userId}`);
        setUserData(res.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, [userId]);

  const deleteUserDocuments = async (userId) => {
    if (!userId) {
      console.error('User ID is not provided');
      return;
    }

    const collections = ['comments', 'correctFollowers'];

    try {
      for (const collectionName of collections) {
        const collectionRef = collection(db, collectionName);

        // Retrieve all documents in the collection
        const querySnapshot = await getDocs(collectionRef);

        // Filter documents based on userId condition
        const deletePromises = querySnapshot.docs
          .filter(docSnapshot => docSnapshot.id.startsWith(userId))
          .map(async (docSnapshot) => {
            try {
              await deleteDoc(doc(db, collectionName, docSnapshot.id));
              // console.log(`Deleted document: ${docSnapshot.id} from ${collectionName}`);
            } catch (error) {
              console.error(`Error deleting document ${docSnapshot.id} from ${collectionName}:`, error);
            }
          });

        await Promise.all(deletePromises);

        // Verify deletion
        const verifySnapshot = await getDocs(collectionRef);
        const remainingDocuments = verifySnapshot.docs
          .filter(docSnapshot => docSnapshot.id.startsWith(userId))
          .map(doc => doc.id);

        // console.log(`Remaining documents for userId prefix starting with ${userId} in ${collectionName}:`, remainingDocuments);

        if (remainingDocuments.length === 0) {
          // console.log(`All documents for userId prefix in ${collectionName} deleted successfully`);
        } else {
          // console.log(`Some documents in ${collectionName} were not deleted.`);
        }
      }
    } catch (error) {
      console.error('Error deleting documents: ', error);
    }
  };

  useEffect(() => {
    if (userId) {
      deleteUserDocuments(userId);
    }
  }, [userId]);

  const handleStartGame = () => {
    if (totalQuestions <= 0 || isNaN(totalQuestions)) {
      alert(t('invalid_questions'));
      return;
    }
    if (timer <= 0 || isNaN(timer)) {
      alert(t('invalid_timer'));
      return;
    }

    navigate(`/${lng}/${userId}/teamschallenge/teamsquestion?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestion=1&timer=${timer}&level=${level}&team1=${team1}&team2=${team2}`);
  };

  if (!userData) return <p>{t('loading')}...</p>;

  return (
    <div className="container mt-3 mx-auto" style={{ maxWidth: '300px' }}>
                <div>
            <span className="fontcolorYellow fw-bold fs-6">  انضم لاحد الفريقين بكتابة <span className="fontcolorWhite">انضم</span> </span>
          </div>
      <div className="bgTransparent rounded-5 px-3 py-2 mt-2">
        <h2 className="mb-4 fontcolorYellow fw-bold">{t('game_settings')}</h2>

        <div className="mb-3 row align-items-between">
          <label htmlFor="hashtag" className="col-5 col-sm-5 align-middle fontcolorWhite">{t('hashtag')}</label>
          <input 
            type="text" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="hashtag" 
            value={hashtag} 
            onChange={e => setHashtag(e.target.value)} 
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="totalQuestions" className="col-5 col-sm-5 align-middle fontcolorWhite">{t('total_questions')}</label>
          <input 
            type="number" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="totalQuestions" 
            value={totalQuestions} 
            onChange={e => setTotalQuestions(parseInt(e.target.value))} 
          />
        </div>
        <div className="mb-3 row align-items-center">
          <label htmlFor="timer" className="col-5 col-sm-5 align-middle fontcolorWhite">{t('question_timer')}</label>
          <input 
            type="number" 
            className="col-5 col-sm-5 fontcolorPrimary fw-semibold" 
            id="timer" 
            value={timer}
            min="1" 
            onChange={e => setTimer(parseInt(e.target.value))} 
          />
          <label htmlFor="timer" className="col-1 col-sm-1 align-middle fontcolorWhite">{t('question_timer_seconds')}</label>
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="level" className="col-5 col-sm-5 align-middle fontcolorWhite">{t('level')}</label>
          <select 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="level" 
            value={level} 
            onChange={e => setLevel(e.target.value)} 
          >
            <option className='fontcolorPrimary fw-semibold' value="firstlevel">{t('first_level')}</option>
            <option className='fontcolorPrimary fw-semibold' value="secondlevel">{t('second_level')}</option>
            <option className='fontcolorPrimary fw-semibold' value="thirdlevel">{t('third_level')}</option>
            <option className='fontcolorPrimary fw-semibold' value="alllevels">{t('all_levels')}</option>
          </select>
        </div>
        <div className="row d-flex justify-content-center" style={{ margin: '1em auto' }}>
          <div className="str col-12 d-flex justify-content-center mt-3">
            <button onClick={handleStartGame} style={{ width: '70px', height: '70px' }} id="start" className="fancy button">
              <h4 className="m-0 fontcolorYellow" style={{ position: 'relative', zIndex: '1' }}>{t('start')}</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsSettings;
