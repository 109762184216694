import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get('https://backend-tok.weblab99.com/users');
        setUsers(res.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleDateChange = (id, date) => {
    setSelectedDate(prevDates => ({
      ...prevDates,
      [id]: date
    }));
  };

  const handleUpdate = async (id) => {
    try {
      const date = selectedDate[id];
      if (!date) {
        alert('Please select a date');
        return;
      }

      // Convert local time to UTC
      const newDateUTC = moment(date).utc().format('YYYY-MM-DD HH:mm:ss');

      const updatedUser = {
        active_until: newDateUTC,
        active: true
      };

      await axios.put(`https://backend-tok.weblab99.com/users/${id}`, updatedUser);

      alert('User updated successfully');

      setUsers(prevUsers =>
        prevUsers.map(u =>
          u.id === id
            ? { ...u, active_until: newDateUTC, active: true }
            : u
        )
      );
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const formatDateTimeForDisplay = (isoString) => {
    if (!isoString) return 'N/A';
    try {
      // Display time in UTC
      return moment(isoString).utc().format('YYYY-MM-DD HH:mm:ss');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  const formatDateForInput = (isoString) => {
    if (!isoString) return '';
    try {
      // Convert UTC time to local time for input
      return moment(isoString).utc().format('YYYY-MM-DDTHH:mm');
    } catch (error) {
      console.error('Error formatting date for input:', error);
      return '';
    }
  };

  return (
    <div className="container" style={{ marginTop: '7rem', direction: 'ltr', maxHeight: '80vh', overflowY: 'auto' }}>
      <h2>Admin Dashboard</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Active</th>
            <th>Sign up Date</th>
            <th>Active Until</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.active ? 'Active' : 'Inactive'}</td>
              <td>{formatDateTimeForDisplay(user.signup_time)}</td>
              <td>{formatDateTimeForDisplay(user.active_until)}</td>
              <td>
                <div className="d-flex justify-content-between">
                  <input
                    type="datetime-local"
                    onChange={(e) => handleDateChange(user.id, e.target.value)}
                    value={formatDateForInput(user.active_until)}
                  />
                  <button onClick={() => handleUpdate(user.id)}>
                    Update Active Until
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboard;
