import React, { useState, useEffect } from 'react';

// Apply saved colors immediately when the script runs
const savedColors = JSON.parse(localStorage.getItem('themeColors')) || {
  primary: '#4d259b',
  border: '#390990',
  fancyback: '#37155a',
  fancycolor: '#ffb900',
  fontcolor: '#ffffff',
};

Object.entries(savedColors).forEach(([key, value]) => {
  document.documentElement.style.setProperty(`--${key}-color`, value);
});

const ColorPicker = () => {
  const defaultColors = {
    primary: '#4d259b',
    border: '#390990',
    fancyback: '#37155a',
    fancycolor: '#ffb900',
    fontcolor: '#ffffff',
  };

  const [colors, setColors] = useState(savedColors);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.entries(colors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}-color`, value);
    });
  }, [colors]);

  const handleColorChange = (key, value) => {
    setColors((prevColors) => ({ ...prevColors, [key]: value }));
  };

  const saveColors = () => {
    localStorage.setItem('themeColors', JSON.stringify(colors));
  };

  const resetColors = () => {
    setColors(defaultColors);
    localStorage.removeItem('themeColors');
  };

  return (
    <div className="color-picker mb-2">
      <div className="color-input-row">
        {Object.entries(colors).map(([key, value]) => (
          <input
            key={key}
            type="color"
            value={value}
            onChange={(e) => handleColorChange(key, e.target.value)}
          />
        ))}
        <button className="btn btn-primary color-picker-buttons" onClick={saveColors}>
          <i className="bi bi-floppy fs-5 fw-bold"></i>
        </button>
        <button className="btn btn-secondary color-picker-buttons" onClick={resetColors}>
          <i className="bi bi-arrow-clockwise fs-5 fw-bold"></i>
        </button>
      </div>
    </div>
  );
};

export default ColorPicker;
