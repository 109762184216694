import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const TeamsStartGame = () => {
  const { lng,userId } = useParams();
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`https://backend-tok.weblab99.com/users/${userId}`);
        setUserData(res.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, [userId]);



  return (
    <div className="container mt-5">
      <h2 className="mb-4 fontcolorYellow fw-bold">{t('start_challenge')}</h2>
      {userData ? (
        <>
          <h3 className="fontcolorWhite">{t('streamer')} <span className="fontcolorYellow">{userData.username}</span></h3>
          {userData.active ? (
            <div className="games text-center mt-1">
              <div className="row justify-content-around p-2 ">
                  <Link
                    to={`/${lng}/${userId}/teamschallenge/teamssettings`}
                    state={{ username: userData.username }}
                    className="card col-md-auto bgTransparent fontcolorWhite"
                    style={{ width: '18rem', textDecoration: 'none', color: 'inherit' }}
                  >
                    <div className="card-body">
                      <h5 className="card-title fontcolorYellow fw-bold">{t('brain_challenge')}</h5>
                      <p className="card-text" dangerouslySetInnerHTML={{ __html: t('description_teams') }}></p>
                      <h6 className="fontcolorYellow">{t('add_hashtag')}</h6>
                      <p className="fw-bold">{t('example_q')}   <span className='fontcolorYellow'>{t('example_q_2')}</span></p>
                    </div>
                  </Link>
              </div>
            </div>
          ) : (
            <div className="subscribe">
              <p>You have to subscribe to access the games.</p>
            </div>
          )}
        </>
      ) : (
        <p>{t('loading')}</p>
      )}
    </div>
  );
};

export default TeamsStartGame;
