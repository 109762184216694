import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResultUpdate = () => {
  const { lng, userId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentQuestionIndex = parseInt(queryParams.get('currentQuestionIndex'), 10);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'), 10) || 0;
  const timer = parseInt(queryParams.get('timer'), 10) || 0;
  const hashtag = queryParams.get('hashtag') || '';
  const level = queryParams.get('level');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [questionData, setQuestionData] = useState(null);
  const [correctFollowers, setCorrectFollowers] = useState([]);
  const [wrongFollowers, setWrongFollowers] = useState([]);
  const [nonFollowers, setNonFollowers] = useState([]);
  const [activeTab, setActiveTab] = useState('correctFollowers');
  const isAutoMode = queryParams.get('isAutoMode') === 'true';


  const handleNext = useCallback(() => {
    if (currentQuestionIndex + 1 < totalQuestions) {
      const storageKey = `quiz_${userId}`;
      const storedData = JSON.parse(localStorage.getItem(storageKey) || '{}');
      localStorage.setItem(storageKey, JSON.stringify({
        ...storedData,
      }));
  
      navigate(`/${lng}/${userId}/brainchallengeupdate/questionupdate?hashtag=${hashtag}&totalQuestions=${totalQuestions}&timer=${timer}&currentQuestionIndex=${currentQuestionIndex + 1}&level=${level}&isAutoMode=${isAutoMode}`);
    } else {
      navigate(`/${lng}/${userId}/brainchallengeupdate/leaderboardupdate?hashtag=${hashtag}&totalQuestions=${totalQuestions}&timer=${timer}&level=${level}&isAutoMode=${isAutoMode}`);
    }
  }, [navigate, lng, userId, hashtag, totalQuestions, timer, currentQuestionIndex, level, isAutoMode]);
  
  

  useEffect(() => {
    if (isAutoMode) {
      const autoScroll = async () => {
        // Show correct followers for 5 seconds
        setActiveTab('correctFollowers');
        await new Promise((resolve) => setTimeout(resolve, 5000));
  
        // If more than 9 comments, scroll through them
        if (correctFollowers.length > 9) {
          scrollComments(correctFollowers.length);
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
  
        // Switch to wrong followers tab
        setActiveTab('wrongFollowers');
        await new Promise((resolve) => setTimeout(resolve, 5000));
  
        // If more than 9 comments, scroll through them
        if (wrongFollowers.length > 9) {
          scrollComments(wrongFollowers.length);
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
  
        // Navigate to the next question or leaderboard
        handleNext();
      };
  
      autoScroll();
    }
  }, [isAutoMode, correctFollowers, wrongFollowers, handleNext]);

  const scrollComments = (commentCount) => {
    const listElement = document.querySelector('.tab-content.custom-scrollbar');
    if (!listElement) return;
  
    const scrollStep = Math.ceil(listElement.scrollHeight / commentCount);
    let scrollPosition = 0;
  
    const interval = setInterval(() => {
      scrollPosition += scrollStep;
      listElement.scrollTo(0, scrollPosition);
  
      if (scrollPosition >= listElement.scrollHeight) {
        clearInterval(interval);
      }
    }, 500); // Adjust the interval for smoothness
  };
  



  useEffect(() => {
    // console.log('Fetching question data for:', { userId, currentQuestionIndex });
  
    const fetchQuestionData = () => {
      const storageKey = `quiz_${userId}`;
      const storedData = JSON.parse(localStorage.getItem(storageKey) || '{}');
  
      // console.log('Stored data:', storedData);
  
      // Find the data corresponding to currentQuestionIndex
      const questionEntry = Object.values(storedData).find(
        (entry) => entry.currentQuestionIndex === currentQuestionIndex
      );
  
      if (questionEntry) {
        // console.log('Question Entry:', questionEntry);
  
        setQuestionData({
          question: questionEntry.question,
          correctAnswer: questionEntry.correctAnswer,
          correctAnswerNum: questionEntry.correctAnswerNum,
        });
  
        const tikData = questionEntry.comments || {};
        const correct = [];
        const wrong = [];
        const nonFollow = [];
        const userProcessed = new Set();
  
        Object.values(tikData).forEach((comment) => {
          if (!userProcessed.has(comment.userId)) {
            userProcessed.add(comment.userId);
  
            if (comment.followRole === 0) {
              nonFollow.push(comment);
            } else if (comment.answer === 'true') {
              correct.push(comment);
            } else if (comment.answer === 'false') {
              wrong.push(comment);
            }
          }
        });
  
        setCorrectFollowers(correct);
        setWrongFollowers(wrong);
        setNonFollowers(nonFollow);
      } else {
        console.error('No data found for the current question.');
      }
    };
  
    fetchQuestionData();
  }, [userId, currentQuestionIndex]);
  




  const renderComments = (comments) => {
    return (
      <div className="tab-content mt-2 custom-scrollbar slow-motion" style={{ maxHeight: '355px', overflowY: 'auto' }}>
        <div className="container mb-2">
          <div className="row">
            {comments.map((comment, index) => (
              <div key={index} className="col-4 col-sm-4 col-md-4">
                <div className="text-center mb-2">
                  <img
                    src={comment.profilePictureUrl}
                    alt="Profile"
                    className="rounded-circle mb-2"
                    width="60"
                  />
                  <h6>{comment.nickname}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  if (!questionData) {
    // console.log('No question data found.');
    return (
      <div>
        <p>{t('error_occurred')}</p>
        <button className="col-5 col-sm-5 col-md-5 fancy button" onClick={handleNext} style={{ width: '100px' }}>
          <h6 className="fontcolorYellow" style={{ position: 'relative', zIndex: '1', lineHeight: 'revert' }}>
            {currentQuestionIndex + 1 < totalQuestions ? t('next') : t('leaderboard')}
          </h6>
        </button>
      </div>
    );
  }

  return (
    <div className="container mt-2">
      <h6 className="quesText mb-3">{questionData.question}</h6>
      <div className="row justify-content-center mb-3 p-2 bgTransparent rounded-3">
        <p className="m-0 col-7 col-sm-7 col-md-7 align-self-center fontcolorWhite">
          {t('question')} : {currentQuestionIndex + 1} / {totalQuestions} -- {t('answer_number')} :{' '}
          {questionData.correctAnswerNum}
          <br /> {t('answer')} : {questionData.correctAnswer}
        </p>
        <button className="col-5 col-sm-5 col-md-5 fancy button align-self-center" onClick={handleNext} style={{ width: '100px' }}>
          <h6 className="fontcolorYellow" style={{ position: 'relative', zIndex: '1', lineHeight: 'revert' }}>
            {currentQuestionIndex + 1 < totalQuestions ? t('next') : t('leaders')}
          </h6>
        </button>
      </div>
      <div className="mb-3 bgTransparent rounded-3">
        <ul className="p-0 nav nav-tabs justify-content-around">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'correctFollowers' ? 'active' : ''}`}
              onClick={() => setActiveTab('correctFollowers')}
            >
              {t('correct')}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'wrongFollowers' ? 'active' : ''}`}
              onClick={() => setActiveTab('wrongFollowers')}
            >
              {t('wrong')}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'nonFollowers' ? 'active' : ''}`}
              onClick={() => setActiveTab('nonFollowers')}
            >
              {t('not_following')}
            </button>
          </li>
        </ul>

        <div className="tab-content mt-2">
          {activeTab === 'correctFollowers' && renderComments(correctFollowers)}
          {activeTab === 'wrongFollowers' && renderComments(wrongFollowers)}
          {activeTab === 'nonFollowers' && renderComments(nonFollowers)}
        </div>
      </div>
    </div>
  );
};

export default ResultUpdate;
