import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

const AskAudience = ({ onFinish, hashtag = 'جواب', currentQuestion, userId, questionOptions = [] }) => {

  const [votes, setVotes] = useState({
    first: [],
    second: [],
    third: [],
    fourth: [],
  });
  const [timeRemaining, setTimeRemaining] = useState(30); // Timer for 30 seconds
  const [collectionFinished, setCollectionFinished] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false); // Control the visibility of the close button
  const socketRef = useRef(null);
  const timerSound = useRef(new Audio('/sounds/timer-sound.mp3')); // Adjust path as needed

  useEffect(() => {
    timerSound.current.preload = 'metadata';

    // Set the volume levels
    timerSound.current.volume = 0.5;
  }, []);

  useEffect(() => {
    if (!collectionFinished && !socketRef.current) {
      socketRef.current = io('https://backend-tok.weblab99.com');

      socketRef.current.on('connect', () => {
        socketRef.current.emit('joinRoom', { userId: userId });
      });

      const handleChat = (data) => {
        if (data && data.state && data.state.roomId && data.data.comment.includes(hashtag)) {
          const userId = data.data.userId;
          const comment = data.data.comment.replace(/\s+/g, '').replace(/\n/g, ''); // Remove spaces and line breaks

          // Convert Arabic numbers to English numbers
          const convertArabicToEnglishNumbers = (str) => {
            const arabicToEnglishMap = {
              '٠': '0',
              '١': '1',
              '٢': '2',
              '٣': '3',
              '٤': '4',
              '٥': '5',
              '٦': '6',
              '٧': '7',
              '٨': '8',
              '٩': '9',
            };
            return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
          };

          const sanitizedComment = convertArabicToEnglishNumbers(comment);

          // Extract answer number
          const match = sanitizedComment.match(new RegExp(`${hashtag}(\\d)`, 'i')); // Match format "جواب1", "جواب2", etc.
          if (match) {
            const answerNumber = match[1];

            setVotes((prevVotes) => {
              const newVotes = { ...prevVotes };

              // Check if user already voted
              const allVotes = Object.values(newVotes).flat();
              const existingVote = allVotes.find((vote) => vote.userId === userId);

              if (!existingVote) {
                // Add user to the respective option
                const optionKey = ['first', 'second', 'third', 'fourth'][answerNumber - 1];
                newVotes[optionKey].push({
                  userId,
                  nickname: data.data.nickname,
                  profilePictureUrl: data.data.profilePictureUrl,
                });
              }

              return newVotes;
            });
          }
        }
      };

      socketRef.current.on('chat', handleChat);

      return () => {
        if (socketRef.current) {
          socketRef.current.off('chat', handleChat);
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }
  }, [hashtag, userId, collectionFinished]);

  useEffect(() => {
    if (timeRemaining > 0) {
      // Play sound when timer starts
      timerSound.current.play();
      timerSound.current.play().catch((error) => {
        console.warn('Error playing sound:', error); // Handle autoplay restrictions
      });

      const timer = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer); // Cleanup the timer
    } else {
      setCollectionFinished(true);
      setShowCloseButton(true); // Ensure the close button shows when time ends
    }
  }, [timeRemaining]);



  return (
    <div className="popup-overlay">
      <div className="ask_audience">
        <div className='row py-2 px-3'>
          <h6 className="col-7 fontcolorWhite fw-bold m-auto">اكتب <span className="fontcolorYellow fw-bold">{hashtag}</span> ثم <span className="fontcolorYellow fw-bold">1,2,3,4</span></h6>
          <h2 className="col bgtransparent text-center fw-bold m-auto"> {timeRemaining}</h2>
          {showCloseButton && (
            <div className="col-2 text-center">
              <button
                onClick={() => onFinish(votes)} // Allow manual closing
                className="fancy button next-question-button"
              >
                <h6 className='m-0 fontcolorWhite' style={{ position: 'relative', zIndex: 1 }}> X </h6>            </button>
            </div>
          )}
        </div>
        <h6 className="fontcolorWhite fw-bold px-3 py-1"> {currentQuestion}</h6>

        <div className="custom-scrollbar slow-motion px-1" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {['first', 'second', 'third', 'fourth'].map((key, index) => (
            <div key={key} className="vote-group mb-4 borderYellow border border-2 rounded">
              <div className="row p-1 mb-2 mx-auto border-bottom border-1">
                <h5 className="col-2 m-0 fontcolorWhite"> {`${index + 1}`} <span className="fontcolorYellow fw-bold">|</span></h5>
                <h5 className="col m-0 text-center fontcolorWhite">
                  {questionOptions[index] || `Option ${index + 1}`}
                </h5>
                <h5 className="col-2 m-0 fontcolorWhite"> <span className="fontcolorYellow fw-bold">|</span> {votes[key].length} </h5>
              </div>

              <div className="row g-1">
                {votes[key].map((vote) => (
                  <div key={vote.userId} className="col-3 col-sm-3 col-md-3 text-center">
                    <img
                      src={vote.profilePictureUrl || 'default-avatar.png'}
                      alt={vote.nickname}
                      className="avatar img-fluid rounded-circle mb-2"
                      style={{ maxWidth: '30px', height: '30px' }}
                    />
                    <h6 style={{ fontSize: '0.5rem' }}>{vote.nickname}</h6>
                  </div>
                ))}
              </div>

            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default AskAudience;
