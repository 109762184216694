import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSound } from '../gameWrappers/SoundContext';
import axios from 'axios';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';

const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const colors = ['#ff008f','#6e00ff','#ff9200', '#fff200','#0000ff','#00ffd2','#00b51d','#ff0000'];

const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

const sanitizeNicknameForDB = (nickname) => {
  const sanitizedNickname = nickname.replace(/[^\p{L}\p{N}\s]/gu, '').trim();
  return sanitizedNickname === '' ? 'unknown' : sanitizedNickname;
};

const generateAnimationStyle = () => {
  const duration = Math.random() * 3 + 1; // Random duration between 1 and 4 seconds
  const delay = Math.random() * 1; // Random delay up to 1 second
  return {
    animationDuration: `${duration}s`,
    animationDelay: `${delay}s`,
  };
};

const NumRound = () => {
  const { lng, userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalRounds = parseInt(queryParams.get('totalRounds'), 10);
  const timer = parseInt(queryParams.get('timer'), 10);
  const currentRound = parseInt(queryParams.get('currentRound'), 10) || 0;
  const level = queryParams.get('level'); // Correctly get level from query parameters
  const { playTimer } = useSound();
  const socketRef = useRef(null);
  const [comments, setComments] = useState([]);
  const [randomNumber, setRandomNumber] = useState(getRandomNumber(9999, 999999));
  const [timeRemaining, setTimeRemaining] = useState(timer);
  const [timeFinished, setTimeFinished] = useState(false);
  const hashtag = queryParams.get('hashtag');
  const [correctComment, setCorrectComment] = useState('');
  const [animationStyles, setAnimationStyles] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    document.documentElement.style.setProperty('--bg1-color', getRandomColor());
    document.documentElement.style.setProperty('--bg2-color', getRandomColor());
    document.documentElement.style.setProperty('--bg3-color', getRandomColor());
    document.documentElement.style.setProperty('--bg4-color', getRandomColor());
    document.documentElement.style.setProperty('--bg5-color', getRandomColor());
    document.documentElement.style.setProperty('--bg6-color', getRandomColor());
    document.documentElement.style.setProperty('--bg7-color', getRandomColor());
    document.documentElement.style.setProperty('--bg8-color', getRandomColor());

    if (!socketRef.current) {
      socketRef.current = io('https://backend-tok.weblab99.com');

      socketRef.current.on('connect', () => {
        socketRef.current.emit('joinRoom', { userId });
      });

      const handleChat = (data) => {
        if (data && data.state && data.state.roomId && data.data.comment.includes(hashtag)) {
          setComments((prevComments) => [...prevComments, data.data]);
          // console.log('New comment received:', data.data);
        }
      };

      socketRef.current.on('chat', handleChat);

      return () => {
        if (socketRef.current) {
          socketRef.current.off('chat', handleChat);
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }
  }, [userId, hashtag]);

  useEffect(() => {
    if (hashtag && randomNumber !== null) {
      const correctComment = hashtag.toString() + randomNumber.toString();
      setCorrectComment(correctComment);
    }
  }, [hashtag, randomNumber]);

  const insertIntoDatabase = useCallback(
    (userId, randomNumber, currentRound, timer, totalRounds, comments) => {
      const commentsData = comments.reduce((acc, comment, index) => {
        const key = `comment${index + 1}`;
        let answer = comment.comment.replace(/\n/g, ' ').replace(/\s+/g, '');

        const convertArabicToEnglishNumbers = (str) => {
          const arabicToEnglishMap = {
            '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
            '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
          };
          return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
        };

        answer = convertArabicToEnglishNumbers(answer);
        const isCorrect = correctComment === answer;

        acc[key] = {
          comment: comment.comment,
          userId: comment.userId,
          nickname: sanitizeNicknameForDB(comment.nickname),
          profilePictureUrl: comment.profilePictureUrl,
          followRole: comment.followRole,
          answer: isCorrect ? 'true' : 'false',
        };
        return acc;
      }, {});

      axios
        .post('https://backend-tok.weblab99.com/insertTikCommentsRounds', {
          userId,
          randomNumber,
          currentRound,
          timer,
          totalRounds,
          comments: commentsData,
        })
        .then(() => {
        })
        .catch((error) => console.error('Error inserting data into tik_comments:', error));
    },
    [correctComment]
  );

  useEffect(() => {
    setComments([]);
    setRandomNumber(getRandomNumber(9999, 999999));
    setTimeRemaining(timer);
    setTimeFinished(false);
  }, [currentRound, timer]);

  useEffect(() => {
    if (level === 'hard') {
      const styles = randomNumber
        .toString()
        .split('')
        .map(() => generateAnimationStyle());
      setAnimationStyles(styles);
    }
  }, [level, randomNumber]);

  useEffect(() => {
    if (timeRemaining <= 0) {
      setTimeFinished(true);

      const timerId = setTimeout(() => {
        insertIntoDatabase(
          userId,
          randomNumber,
          currentRound,
          timer,
          totalRounds,
          comments
        );

        setTimeout(() => {
          navigate(`/${lng}/${userId}/numberchallenge/numresult?hashtag=${hashtag}&totalRounds=${totalRounds}&currentRound=${currentRound}&timer=${timer}&level=${level}`, {
            state: { comments }
          });
        }, 3000);

      }, 2000);

      return () => clearTimeout(timerId);
    }

    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeRemaining, navigate, userId, totalRounds, currentRound, timer, comments, insertIntoDatabase, randomNumber, hashtag, level, lng]);

  useEffect(() => {
    if (timeRemaining === 10) {
      playTimer();
    }
  }, [timeRemaining, playTimer]);

  return (
    <div className="container mt-2">
      <h4 className="numhide">{t('round')} {currentRound} / {totalRounds}</h4>

      <div className="fancy ques" id="ques">
        <div className={`bgm ${timeFinished ? 'hide-bgm' : ''}`} style={{ direction: 'rtl' }}>
          <div className="bgm1">
            <svg>
              <filter id="noise">
                <feTurbulence id="turbulence">
                  <animate attributeName="baseFrequency" dur="50s" values="0.9 0.9;0.8 0.8; 0.9 0.9" repeatCount="indefinite"></animate>
                </feTurbulence>
                <feDisplacementMap in="SourceGraphic" scale="60"></feDisplacementMap>
              </filter>
            </svg>
          </div>
          <div className="bgm2">
            <svg>
              <filter id="noise">
                <feTurbulence id="turbulence">
                  <animate attributeName="baseFrequency" dur="50s" values="0.9 0.9;0.8 0.8; 0.9 0.9" repeatCount="indefinite"></animate>
                </feTurbulence>
                <feDisplacementMap in="SourceGraphic" scale="60"></feDisplacementMap>
              </filter>
            </svg>
          </div>
          <div className="bgm3">
            <svg>
              <filter id="noise">
                <feTurbulence id="turbulence">
                  <animate attributeName="baseFrequency" dur="50s" values="0.9 0.9;0.8 0.8; 0.9 0.9" repeatCount="indefinite"></animate>
                </feTurbulence>
                <feDisplacementMap in="SourceGraphic" scale="-60"></feDisplacementMap>
              </filter>
            </svg>
          </div>
          <div className="bgm4">
            <svg>
              <filter id="noise">
                <feTurbulence id="turbulence">
                  <animate attributeName="baseFrequency" dur="50s" values="0.9 0.9;0.8 0.8; 0.9 0.9" repeatCount="indefinite"></animate>
                </feTurbulence>
                <feDisplacementMap in="SourceGraphic" scale="-60"></feDisplacementMap>
              </filter>
            </svg>
          </div>
          <h1 className={`numhideP m-0 ${timeFinished ? 'show-numhideP' : ''}`}>
          {level === 'hard' 
            ? randomNumber.toString().split('').reverse().map((digit, index) => (
              <span 
                key={index} 
                className={`digit ${timeFinished ? '' : 'move'}`} 
                style={timeFinished ? {} : animationStyles[index]}
              >
                {digit}
              </span>
            )) 
            : randomNumber
          }
          </h1>
        </div>
      </div>
      <div className="bgTransparent rounded-4 mt-4 mb-2">
        <h6 className="info fontcolorWhite">{t('share_hashtag')} </h6>
        <h6 className="info fontcolorWhite">
        {t('hashtag_today')} : <span className="fontcolorYellow">{hashtag}</span> , {t('example')} :{' '}
          <span className="fontcolorYellow">{hashtag} 123456</span>{' '}
        </h6>
      </div>
      <h5 className="fontcolorWhite mt-4">{t('remaining_time')} : <span className="fontcolorYellow">{timeRemaining}</span></h5>
    </div>
  );
};

export default NumRound;
