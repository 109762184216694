import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const MemSettings = () => {
  const { lng, userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [totalRounds, settotalRounds] = useState(10);
  const [timer, setTimer] = useState(15);
  const [timer_answer, setTimer_answer] = useState(25);
  const navigate = useNavigate();
  const [hashtag, setHashtag] = useState('');
  const [totalShapes, setTotalShapes] = useState(20);
  const { t } = useTranslation();

  useEffect(() => {
    // Set the translated value when the component mounts
    setHashtag(t('hashtag_placeholder'));
  }, [t]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`https://backend-tok.weblab99.com/users/${userId}`);
        setUserData(res.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const deleteTikComments = async () => {
      try {
        const token = localStorage.getItem('token');
    
        await axios.post(
          `https://backend-tok.weblab99.com/deleteTikComments`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(`Deleted tik_comments for user_id: ${userId}`);
      } catch (error) {
        console.error('Error deleting tik_comments:', error);
      }
    };
    
    deleteTikComments();
  }, [userId]);


  const handleTotalShapes = (e) => {
    setTotalShapes(parseInt(e.target.value));
  };

  const handleStartGame = () => {
    if (totalRounds <= 0 || isNaN(totalRounds)) {
      alert('Please enter a valid number of rounds.');
      return;
    }
    if (timer <= 0 || isNaN(timer)) {
      alert('Please enter a valid timer value.');
      return;
    }
    if (timer_answer <= 0 || isNaN(timer_answer)) {
      alert('Please enter a valid timer value.');
      return;
    }

    navigate(`/${lng}/${userId}/memorychallenge/memround?hashtag=${hashtag}&totalRounds=${totalRounds}&currentRound=1&timer=${timer}&timer_answer=${timer_answer}&totalShapes=${totalShapes}`);
  };

  if (!userData) return <p>{t('loading')}</p>;

  return (
    <div className="container mt-1">
      <div className="bgTransparent rounded-5 px-3 py-2 mt-2">
        <h2 className="mb-4 fontcolorYellow fw-bold">{t('game_settings')}</h2>
        <div className="mb-3 row align-items-between">
          <label htmlFor="hashtag" className="form-label col-5 col-sm-5  align-middle fontcolorWhite">{t('hashtag')}</label>
          <input 
            type="text" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="hashtag" 
            value={hashtag} 
            onChange={e => setHashtag(e.target.value)} 
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="totalRounds" className="form-label col-5 col-sm-5  align-middle fontcolorWhite">{t('total_rounds')}</label>
          <input 
            type="number" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="totalRounds" 
            value={totalRounds} 
            onChange={e => settotalRounds(parseInt(e.target.value))} 
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="timer" className="form-label col-5 col-sm-5  align-middle fontcolorWhite">{t('round_time')}</label>
          <input 
            type="number" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="timer" 
            value={timer} 
            onChange={e => setTimer(parseInt(e.target.value))} 
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="timer_answer" className="form-label col-5 col-sm-5  align-middle fontcolorWhite">{t('round_time_answer')}</label>
          <input 
            type="number" 
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold" 
            id="timer_answer" 
            value={timer_answer} 
            onChange={e => setTimer_answer(parseInt(e.target.value))} 
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="totalShapes" className="form-label col-5 col-sm-5  align-middle fontcolorWhite"> {t('totalShapes')} ({totalShapes})</label>
          <input
            type="range"
            className="px-0 col-7 col-sm-7"
            id="totalShapes"
            min="1"
            max="24"
            value={totalShapes}
            onChange={handleTotalShapes}
          />
        </div>
        <div className="row d-flex justify-content-center" style={{margin: '1em auto'}}>
          <div className="str col-12 d-flex justify-content-center mt-3">
            <button onClick={handleStartGame} style={{width: '70px', height: '70px'}} id="start" className="fancy button">
              <h5 className="m-0" style={{ position: 'relative', zIndex: '1'}}>{t('start')}</h5>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemSettings;

