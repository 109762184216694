import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSound } from '../gameWrappers/SoundContext';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';
import { useMembers } from './MembersContext';
// import  questionsAr from './questions_ara.json';

const TeamsQuestion = () => {
  const { lng, userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalQuestions = parseInt(queryParams.get('totalQuestions'));
  const timer = parseInt(queryParams.get('timer'));
  const currentQuestion = parseInt(queryParams.get('currentQuestion')) || 0;
  const hashtag = queryParams.get('hashtag');
  const [questions, setQuestions] = useState([]);
  const [timeLeft, setTimeLeft] = useState(timer);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [comments, setComments] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timerRunning, setTimerRunning] = useState(true);
  const socketRef = useRef(null);
  const timerIdRef = useRef(null);
  const [correctAnswerNum, setCorrectAnswerNum] = useState(null);
  const [correctComment, setCorrectComment] = useState('');
  const level = queryParams.get('level');
  const { playTimer, playSwing } = useSound();
  const { t } = useTranslation();
  const { members, setMembers } = useMembers();

  const getCorrectAnswerNum = (question) => {
    if (question.correct === question.first) return 1;
    if (question.correct === question.second) return 2;
    if (question.correct === question.third) return 3;
    if (question.correct === question.fourth) return 4;
    return null;
  };

  useEffect(() => {
    if (hashtag && correctAnswerNum !== null) {
      const correctComment = hashtag.toString() + correctAnswerNum.toString();
      setCorrectComment(correctComment);
      console.log(correctAnswerNum)
    }
  }, [hashtag, correctAnswerNum]);
  useEffect(() => {
    if (questions.length > 0) {
      setCorrectAnswerNum(getCorrectAnswerNum(questions[currentQuestion]));
    }
  }, [questions, currentQuestion]);

  // useEffect(() => {
  //   const storedQuestions = localStorage.getItem('questions_ar');
  //   if (!storedQuestions) {
  //     localStorage.setItem('questions_ar', JSON.stringify(questionsAr));
  //   }
  // }, []);


useEffect(() => {
  const fetchQuestions = async () => {
    try {
      const baseUrl = lng === 'en' ? '/questionsEn' : '/questionsAr';
      const fileName = 'questions_ar.json';
      const response = await fetch(`${baseUrl}/${fileName}`);
      const data = await response.json();
      
      // Filter questions based on the selected level
      let filteredQuestions;
      
      switch (level) {
        case 'firstlevel':
          filteredQuestions = data.filter(item => item.difficulty === 'easy');
          break;
        case 'secondlevel':
          filteredQuestions = data.filter(item => item.difficulty === 'medium');
          break;
        case 'thirdlevel':
          filteredQuestions = data.filter(item => item.difficulty === 'hard');
          break;
        case 'alllevels':
          filteredQuestions = data; // No filtering needed
          break;
        default:
          filteredQuestions = data; // Default case, same as 'alllevels'
          break;
      }
      
      // Shuffle the questions randomly
      const shuffledQuestions = filteredQuestions.sort(() => Math.random() - 0.5);
  
      setQuestions(shuffledQuestions);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching questions:', error);
      setLoading(false);
    }
  };

  fetchQuestions();
}, [lng, level]);

  

  const updateLocalStorage = useCallback((userId, totalQuestions, currentQuestion, correctAnswerNum, correctComment, comments) => {
    let storedMembers = { ...members };
  
    const convertArabicToEnglishNumbers = (str) => {
      const arabicToEnglishMap = {
        '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
        '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9'
      };
      return str.replace(/[٠-٩]/g, match => arabicToEnglishMap[match]);
    };
  
    comments.forEach(comment => {
      const uniqueId = comment.uniqueId;
      let answer = comment.comment.replace(/\n/g, ' ').replace(/\s+/g, '');
      answer = convertArabicToEnglishNumbers(answer);
  
      const isCorrect = correctComment === answer;
      if (!storedMembers[uniqueId]) {
        storedMembers[uniqueId] = {
          pointsQuestion: 0,
          points: 0,
          questions: []
        };
      }
  
      const existingMember = storedMembers[uniqueId];
  
      // Ensure 'questions' is always an array
      if (!existingMember.questions) {
        existingMember.questions = [];
      }
  
      const existingQuestion = existingMember.questions.find(q => q.currentQuestion === currentQuestion);
  
      if (!existingQuestion) {
        existingMember.questions.push({
          currentQuestion,
          answer: isCorrect ? 'true' : 'false',
        });
  
        if (isCorrect) {
          existingMember.pointsQuestion += 500;
          existingMember.points += 500;
        } else {
          existingMember.pointsQuestion -= 500;
          existingMember.points -= 500;
        }
      }
  
      storedMembers[uniqueId] = existingMember;
    });
  
    setMembers(storedMembers); // Update the context state, which also updates local storage
  }, [members, setMembers]);
  

  const handleQuestionEnd = useCallback(() => {
    if (questions.length === 0 || !questions[currentQuestion]) {
      console.error('Current question data is undefined');
      return;
    }

    // const currentQuestionData = questions[currentQuestion];

    updateLocalStorage(
      userId,
      totalQuestions,
      currentQuestion,
      correctAnswerNum,
      correctComment,
      comments
    );
    

    setTimeout(() => {
      navigate(
        `/${lng}/${userId}/teamschallenge/teamsresult?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestion=${currentQuestion}&timer=${timer}&level=${level}`,
        {
          state: {
            comments: filteredComments,
            correctAnswerNum,
            questionText: questions[currentQuestion].question,
            correctAnswer: questions[currentQuestion].correct
          }
        }
      );
    }, 3000);
  }, [
    comments,
    correctComment,
    correctAnswerNum,
    currentQuestion,
    filteredComments,
    hashtag,
    updateLocalStorage,
    navigate,
    questions,
    timer,
    totalQuestions,
    userId,
    level,
    lng
  ]);

  useEffect(() => {
    const handleTimeout = () => {
      if (timeLeft > 0 && timerRunning) {
        setTimeLeft(prevTime => prevTime - 1);
      } else if (timeLeft === 0 && timerRunning) {
        setTimerRunning(false);
        setTimeout(() => {
          handleQuestionEnd();
        }, 1000);
      }
    };

    timerIdRef.current = setInterval(handleTimeout, 1000);
    return () => clearInterval(timerIdRef.current);
  }, [timeLeft, timerRunning, handleQuestionEnd]);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://backend-tok.weblab99.com');

      socketRef.current.on('connect', () => {
        console.log('Connected to socket quiz');
        socketRef.current.emit('joinRoom', { userId });
      });

      const handleChat = (data) => {
        if (data && data.state && data.state.roomId && data.data.comment.includes(hashtag)) {
          setComments((prevComments) => [...prevComments, data.data]);
          // console.log('New comment received:', data.data);
        }
      };

      socketRef.current.on('chat', handleChat);

      return () => {
        if (socketRef.current) {
          socketRef.current.off('chat', handleChat);
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }
  }, [userId, hashtag]);
  


  useEffect(() => {
    const regex = new RegExp(`^${hashtag}\\s?(\\d|[١٢٣٤])$`, 'i');
    const matchedComments = comments.filter(comment => regex.test(comment.comment));
    const enhancedComments = matchedComments.map(comment => {
      const match = comment.comment.match(regex);
      if (match) {
        const answerNumber = match[1];
        let answer = '';
        if (answerNumber === "1" || answerNumber === "١") answer = questions[currentQuestion].first;
        if (answerNumber === "2" || answerNumber === "٢") answer = questions[currentQuestion].second;
        if (answerNumber === "3" || answerNumber === "٣") answer = questions[currentQuestion].third;
        if (answerNumber === "4" || answerNumber === "٤") answer = questions[currentQuestion].fourth;
        return { ...comment, answer };
      }
      return comment;
    });
    setFilteredComments(enhancedComments);
  }, [comments, hashtag, questions, currentQuestion]);

  useEffect(() => {
    setTimeLeft(timer);
    setShowCorrectAnswer(false);
    setComments([]);
    setFilteredComments([]);
    setTimerRunning(true);
  }, [currentQuestion, timer]);

  useEffect(() => {
    if (timeLeft === 10) {
      playTimer();
    }
  }, [timeLeft, playTimer]);


  useEffect(() => {
    if ( timeLeft === timer - 1) {
      playSwing();
    }
  }, [timeLeft, playSwing, timer]);

  useEffect(() => {
    if (timeLeft < 1 ) {
      setShowCorrectAnswer(true);
    }
  }, [timeLeft]);



  if (loading) return <p>{t('loading')}</p>;
  if (questions.length === 0) return <p>No questions available.</p>;

  if (!questions[currentQuestion]) return <p>Loading question...</p>;

  return (
    <div className="container mt-2 rtl">
      <div>
      <div className="fancy ques" id="ques">          {lng === 'en' ? (
              <>
              <h5 className="quesText">{questions[currentQuestion].question}</h5>
              </>
            ) : (
              <>
              <h4 className="quesText">{questions[currentQuestion].question}</h4>
              </>
            )}

      </div>
      <div className="fancy quesnum fw-bold"><span className="numberQues">{t('question')} : {currentQuestion} / {totalQuestions}</span>
      </div>
      <div className="fancy questime"><span className="numbertime">{timeLeft}</span></div>
      </div>
      <div className="bgTransparent rounded-4 mt-4 mb-2 mx-auto" style={{ maxWidth: '300px' }}>
      <h6 className="info">{t('add_hashtag')}</h6>
      <h6 className="info">{t('hashtag_today')} : <span className="fontcolorWhite">{hashtag}</span> , {t('example_q')} : <span className="fontcolorWhite">{hashtag} 1</span> </h6>
      </div>
      <div className="list-group">
        <ul className='p-0'>
          <li className={` answer fade-in ${showCorrectAnswer && correctAnswerNum === 1 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className='answerSpan fontcolorWhite'>1<span className="fontcolorYellow mx-3">|</span>{questions[currentQuestion].first}</h5>
          </li>
          <li className={` answer fade-out ${showCorrectAnswer && correctAnswerNum === 2 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className='answerSpan fontcolorWhite'>2<span className="fontcolorYellow mx-3">|</span>{questions[currentQuestion].second}</h5>
          </li>
          <li className={` answer fade-in ${showCorrectAnswer && correctAnswerNum === 3 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className='answerSpan fontcolorWhite'>3<span className="fontcolorYellow mx-3">|</span>{questions[currentQuestion].third}</h5>
          </li>
          <li className={` answer fade-out ${showCorrectAnswer && correctAnswerNum === 4 ? 'animate__animated animate__flash bg-success' : ''}`}>
            <h5 className='answerSpan fontcolorWhite'>4<span className="fontcolorYellow mx-3">|</span>{questions[currentQuestion].fourth}</h5>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TeamsQuestion;
