import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const SettingsUpdate = () => {
  const { lng, userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(15);
  const [timer, setTimer] = useState(40);
  const [hashtag, setHashtag] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAutoMode, setIsAutoMode] = useState(false);


  useEffect(() => {
    setHashtag(t('hashtag_placeholder'));
  }, [t]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`https://backend-tok.weblab99.com/users/${userId}`);
        setUserData(res.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    const storageKey = `dif_${userId}`;
    // Empty the storage key but not delete it
    localStorage.setItem(storageKey, '');
    fetchUserData();
  }, [userId]);

  const handleStartGame = () => {
    if (totalQuestions <= 0 || isNaN(totalQuestions)) {
      alert(t('invalid_questions'));
      return;
    }
    if (timer <= 0 || isNaN(timer)) {
      alert(t('invalid_timer'));
      return;
    }

    navigate(`/${lng}/${userId}/brainchallenge/question?hashtag=${hashtag}&totalQuestions=${totalQuestions}&currentQuestion=1&timer=${timer}&isAutoMode=${isAutoMode}`);
  };

  if (!userData) return <p>{t('loading')}...</p>;

  return (
    <div className="container mt-1">
      <div className="bgTransparent rounded-5 px-3 py-2 mt-2">
        <h2 className="mb-4 fontcolorYellow fw-bold">{t('game_settings')}</h2>
        <div className="mb-3 row align-items-between">
          <label htmlFor="hashtag" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('hashtag')}</label>
          <input
            type="text"
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="hashtag"
            value={hashtag}
            onChange={e => setHashtag(e.target.value)}
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="totalQuestions" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('total_questions')}</label>
          <input
            type="number"
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="totalQuestions"
            value={totalQuestions}
            onChange={e => setTotalQuestions(parseInt(e.target.value))}
          />
        </div>
        <div className="mb-3 row align-items-between">
          <label htmlFor="timer" className="form-label col-5 col-sm-5 align-middle fontcolorWhite">{t('question_timer')}</label>
          <input
            type="number"
            className="col-7 col-sm-7 fontcolorPrimary fw-semibold"
            id="timer"
            value={timer}
            min="1"
            onChange={e => setTimer(parseInt(e.target.value))}
          />
        </div>
        <div className="mb-3 text-end">
          <label htmlFor="autoMode" className="form-label col-5 col-sm-5 align-middle fontcolorWhite text-center">{t('auto_mode')}</label>
          <input
            type="checkbox"
            id="autoMode"
            checked={isAutoMode}
            onChange={(e) => setIsAutoMode(e.target.checked)}
          />
        </div>
        <div className="row d-flex justify-content-center" style={{ margin: '1em auto' }}>
          <div className="str col-12 d-flex justify-content-center mt-3">
            <button onClick={handleStartGame} style={{ width: '70px', height: '70px' }} id="start" className="fancy button">
              <h4 className="m-0 fontcolorYellow" style={{ position: 'relative', zIndex: '1' }}>{t('start')}</h4>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsUpdate;
