import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSound } from '../gameWrappers/SoundContext';
import axios from 'axios';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';

const circleRed = { width: '18px', height: '18px', backgroundColor: 'red', borderRadius: '50%' };
const circleBlue = { width: '18px', height: '18px', backgroundColor: 'blue', borderRadius: '50%' };
const circleGreen = { width: '18px', height: '18px', backgroundColor: 'green', borderRadius: '50%' };
const circleYellow = { width: '18px', height: '18px', backgroundColor: 'yellow', borderRadius: '50%' };
const squareRed = { width: '18px', height: '18px', backgroundColor: 'red' };
const squareBlue = { width: '18px', height: '18px', backgroundColor: 'blue' };
const squareGreen = { width: '18px', height: '18px', backgroundColor: 'green' };
const squareYellow = { width: '18px', height: '18px', backgroundColor: 'yellow' };
const rectangularRed = { width: '18px', height: '10px', backgroundColor: 'red' };
const rectangularBlue = { width: '18px', height: '10px', backgroundColor: 'blue' };
const rectangularGreen = { width: '18px', height: '10px', backgroundColor: 'green' };
const rectangularYellow = { width: '18px', height: '10px', backgroundColor: 'yellow' };
const triangleRed = { width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '20px solid red' };
const triangleBlue = { width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '20px solid blue' };
const triangleGreen = { width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '20px solid green' };
const triangleYellow = { width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderBottom: '20px solid yellow' };
const MoonRed = { width: '18px', height: '18px', borderRadius: '50%', boxShadow: 'inset 5px 5px 0 0 red', rotate : '270deg' };
const MoonBlue = { width: '18px', height: '18px', borderRadius: '50%', boxShadow: 'inset 5px 5px 0 0 blue', rotate : '270deg' };
const MoonGreen = { width: '18px', height: '18px', borderRadius: '50%', boxShadow: 'inset 5px 5px 0 0 green', rotate : '270deg' };
const MoonYellow = { width: '18px', height: '18px', borderRadius: '50%', boxShadow: 'inset 5px 5px 0 0 yellow', rotate : '270deg' };

const shapesArray = [
  circleRed, circleBlue, circleGreen, circleYellow,
  squareRed, squareBlue, squareGreen, squareYellow,
  rectangularRed, rectangularBlue, rectangularGreen, rectangularYellow,
  triangleRed, triangleBlue, triangleGreen, triangleYellow,
  MoonRed, MoonBlue, MoonGreen, MoonYellow
];

const sanitizeNicknameForDB = (nickname) => {
  const sanitizedNickname = nickname.replace(/[^\p{L}\p{N}\s]/gu, '').trim();
  return sanitizedNickname === '' ? 'unknown' : sanitizedNickname;
};

// Translation object for colors and shapes
const translations = {
  en: {
    shapes: {
      circle: 'circle',
      square: 'square',
      rectangular: 'rectangular',
      triangle: 'triangle',
      Moon: 'Moon'
    },
    colors: {
      red: 'red',
      blue: 'blue',
      green: 'green',
      yellow: 'yellow'
    }
  },
  ar: {
    shapes: {
      circle: 'دائرة',
      square: 'مربع',
      rectangular: 'مستطيل',
      triangle: 'مثلث',
      Moon: 'قمر'
    },
    colors: {
      red: 'احمر',
      blue: 'ازرق',
      green: 'اخضر',
      yellow: 'اصفر'
    }
  }
};

const MemRound = () => {
  const { lng, userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const totalRounds = parseInt(queryParams.get('totalRounds'), 10);
  const timer = parseInt(queryParams.get('timer'), 10);
  const timer_answer = parseInt(queryParams.get('timer_answer'), 10);
  const totalShapes = parseInt(queryParams.get('totalShapes'), 10) || 20;
  const currentRound = parseInt(queryParams.get('currentRound'), 10) || 0;
  const { playTimer } = useSound();
  const socketRef = useRef(null);
  const [comments, setComments] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(timer);
  const [timerRemainingAnswer, setTimerRemainingAnswer] = useState(timer_answer);
  const [timeFinished, setTimeFinished] = useState(false);
  const [timeAnswerFinished, setTimeAnswerFinished] = useState(false);
  const [shapesDisplayed, setShapesDisplayed] = useState([]);
  const [question, setQuestion] = useState(null);
  const [quesAnswer, setQuesAnswer] = useState(null);
  const hashtag = queryParams.get('hashtag');
  const [correctComment, setCorrectComment] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (hashtag && quesAnswer !== null) {
      const correctComment = hashtag.toString() + quesAnswer.toString();
      setCorrectComment(correctComment);
    }
  }, [hashtag, quesAnswer]);

  const insertIntoDatabase = useCallback(
    (userId, quesAnswer, currentRound, timer, totalRounds, comments) => {
      const commentsData = comments.reduce((acc, comment, index) => {
        const key = `comment${index + 1}`;
        let answer = comment.comment.replace(/\n/g, ' ').replace(/\s+/g, '');

        const convertArabicToEnglishNumbers = (str) => {
          const arabicToEnglishMap = {
            '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
            '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9',
          };
          return str.replace(/[٠-٩]/g, (match) => arabicToEnglishMap[match]);
        };

        answer = convertArabicToEnglishNumbers(answer);
        const isCorrect = correctComment === answer;

        acc[key] = {
          comment: comment.comment,
          userId: comment.userId,
          nickname: sanitizeNicknameForDB(comment.nickname),
          profilePictureUrl: comment.profilePictureUrl,
          followRole: comment.followRole,
          answer: isCorrect ? 'true' : 'false',
        };
        return acc;
      }, {});

      axios
        .post('https://backend-tok.weblab99.com/insertTikCommentsRounds', {
          userId,
          randomNumber: quesAnswer,
          currentRound,
          timer,
          totalRounds,
          comments: commentsData,
        })
        .then(() => {
          // console.log('Data inserted into tik_comments successfully');
        })
        .catch((error) => console.error('Error inserting data into tik_comments:', error));
    },
    [correctComment]
  );

  useEffect(() => {
    setComments([]);
    setTimeRemaining(timer);
    setTimeFinished(false);

    // Display random shapes
    const randomShapes = Array.from({ length: totalShapes }, () => {
      const randomIndex = Math.floor(Math.random() * shapesArray.length);
      return shapesArray[randomIndex];
    });
    setShapesDisplayed(randomShapes);

    // Count shapes
    const shapeCounts = {};
    randomShapes.forEach((shape) => {
      let shapeType = '';
      let color = '';

      if (shape.borderRadius === '50%') {
        if (shape.boxShadow) {
          shapeType = 'Moon';
          color = shape.boxShadow.includes('red') ? 'red' : shape.boxShadow.includes('blue') ? 'blue' : shape.boxShadow.includes('green') ? 'green' : 'yellow';
        } else {
          shapeType = 'circle';
          color = shape.backgroundColor;
        }
      } else if (shape.width === '18px' && shape.height === '18px') {
        shapeType = 'square';
        color = shape.backgroundColor;
      } else if (shape.width === '18px' && shape.height === '10px') {
        shapeType = 'rectangular';
        color = shape.backgroundColor;
      } else if (shape.borderBottom) {
        shapeType = 'triangle';
        color = shape.borderBottom.includes('red') ? 'red' : shape.borderBottom.includes('blue') ? 'blue' : shape.borderBottom.includes('green') ? 'green' : 'yellow';
      }

      const key = `${shapeType}_${color}`;
      shapeCounts[key] = (shapeCounts[key] || 0) + 1;
    });

    // Get translations
    const { shapes, colors } = translations[lng];

    // Generate random question
    const questionKeys = Object.keys(shapeCounts);
    const randomQuestionKey = questionKeys[Math.floor(Math.random() * questionKeys.length)];
    const [shapeType, color] = randomQuestionKey.split('_');

    let questionText;
    if (lng === 'ar') {
      questionText = `${t('how_many')} ${shapes[shapeType]} ${colors[color]} ${t('do_you_Seen')}`;
    } else {
      questionText = `${t('how_many')} ${colors[color]} ${shapes[shapeType]} ${t('do_you_Seen')}`;
    }

    setQuestion(questionText);
    setQuesAnswer(shapeCounts[randomQuestionKey]);
    console.log('Question:', questionText, 'Answer:', shapeCounts[randomQuestionKey]);
  }, [currentRound, timer, t, lng, totalShapes]);


  useEffect(() => {
    if (timeRemaining > 0) {
      const timerId = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
      setTimeFinished(true);
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (timerRemainingAnswer === 10) {
      playTimer();
    }
  }, [timerRemainingAnswer, playTimer]);

  useEffect(() => {
    if (timeFinished && timerRemainingAnswer > 0 && !socketRef.current) {
      // console.log("Connecting to WebSocket...");

      socketRef.current = io('https://backend-tok.weblab99.com');

      socketRef.current.on('connect', () => {
        // console.log("WebSocket connected");
        socketRef.current.emit('joinRoom', { userId });
      });

      const handleChat = (data) => {
        if (data && data.state && data.state.roomId && data.data.comment.includes(hashtag)) {
          setComments((prevComments) => [...prevComments, data.data]);
          // console.log('New comment received:', data.data);
        }
      };

      socketRef.current.on('chat', handleChat);

      return () => {
        if (timerRemainingAnswer <= 0) {
          setTimeAnswerFinished(true);
          if (timeFinished && timerRemainingAnswer <= 0) {
            if (socketRef.current) {
              // console.log("Disconnecting from WebSocket...");
              socketRef.current.off('chat', handleChat);
              socketRef.current.disconnect();
              socketRef.current = null;
            }
          }
        }
      };
    }
  }, [timeFinished, timerRemainingAnswer, userId, hashtag]);

  useEffect(() => {
    if (timeAnswerFinished && socketRef.current) {
      // console.log("Disconnecting from WebSocket due to timer end...");
      socketRef.current.off('chat');
      socketRef.current.disconnect();
      socketRef.current = null;
    }
  }, [timeAnswerFinished]);
  

  useEffect(() => {
    if (timeFinished) {
      const intervalId = setInterval(() => {
        setTimerRemainingAnswer((prevTime) => {
          const newTime = prevTime - 1;
          if (newTime <= 0) {
            clearInterval(intervalId);
            setTimeAnswerFinished(true);
            setTimeout(() => {
              insertIntoDatabase(
                userId,
                quesAnswer,
                currentRound,
                timer,
                totalRounds,
                comments
              );

              setTimeout(() => {
                navigate(`/${lng}/${userId}/memorychallenge/memresult?hashtag=${hashtag}&totalRounds=${totalRounds}&currentRound=${currentRound}&timer=${timer}&timer_answer=${timer_answer}&totalShapes=${totalShapes}`, {
                  state: { comments }
                });
              }, 3000);
            }, 1000); 
          }
          return newTime;
          
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeFinished, userId, quesAnswer, currentRound, timer, totalRounds, comments, navigate, lng, hashtag, totalShapes, insertIntoDatabase, timer_answer]);

  return (
    <div className="container mt-2">
      <h4 className="numhide">{t('round')} {currentRound} / {totalRounds}</h4>
      <h5 className="fontcolorWhite mt-4">{t('remember')}</h5>
      <div className="fancy ques" id="ques_mem">
        <div className="answerMem row row-cols-7 gap-1">
          {!timeFinished && shapesDisplayed.map((shape, index) => (
            <div key={index} className="col mb-2">
              <div style={shape}></div>
            </div>
          ))}
          {timeFinished && (
            <div className="col-12">
              <h4 className="fontcolorWhite">{question}</h4>
              <h6 className="fontcolorYellow mt-4">{t('remember_answer')}</h6>
            </div>
          )}
        </div>
      </div>

      <div className="bgTransparent rounded-4 mt-4 mb-2">
        <h6 className="info fontcolorWhite">{t('share_hashtag')} </h6>
        <h6 className="info fontcolorWhite">
          {t('hashtag_today')} : <span className="fontcolorYellow">{hashtag}</span> , {t('example')} :{' '}
          <span className="fontcolorYellow">{hashtag} 1</span>{' '}
        </h6>
      </div>
      <h5 className="fontcolorWhite mt-4">{t('timer_question')} : <span className="fontcolorYellow">{timeRemaining}</span></h5>
      {timeFinished && <h5 className="fontcolorWhite mt-4">{t('timer_answer')} : <span className="fontcolorYellow">{Math.max(timerRemainingAnswer, 0)}</span></h5>}
    </div>
  );
};

export default MemRound;
