import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TeamsResult = () => {
  const { lng, userId } = useParams();
  const location = useLocation();
  const { correctAnswerNum, correctAnswer } = location.state || {};
  const queryParams = new URLSearchParams(location.search);
  const currentQuestion = parseInt(queryParams.get('currentQuestion'), 10);
  const [correctFollowers, setCorrectFollowers] = useState([]);
  const [wrongFollowers, setWrongFollowers] = useState([]);
  const [nonFollowers, setNonFollowers] = useState([]);
  const [activeTeam, setActiveTeam] = useState('team1');
  const [activeSubTab, setActiveSubTab] = useState('correctFollowers');
  const [topMemberTeam1, setTopMemberTeam1] = useState(null);
  const [topMemberTeam2, setTopMemberTeam2] = useState(null);
  const [team1Count, setTeam1Count] = useState(0);
  const [team2Count, setTeam2Count] = useState(0);
  const navigate = useNavigate();
  const totalQuestions = parseInt(queryParams.get('totalQuestions'), 10) || 0;
  const timer = parseInt(queryParams.get('timer'), 10) || 0;
  const hashtag = queryParams.get('hashtag') || '';
  const level = queryParams.get('level');
  const { t } = useTranslation();

  useEffect(() => {
    // Retrieve members data from local storage
    const membersData = JSON.parse(localStorage.getItem('members')) || {};
  
    const teamCorrectFollowers = [];
    const teamWrongFollowers = [];
    const teamNonFollowers = [];
  
    let maxPointsQuestionTeam1 = -1;
    let maxPointsQuestionTeam2 = -1;
    let topMemberTeam1 = null;
    let topMemberTeam2 = null;
    let countTeam1 = 0;
    let countTeam2 = 0;
  
    Object.values(membersData).forEach(member => {
      const { followRole, questions = [], team, pointsQuestion, profilePictureUrl, nickname } = member;
  
      // Check if the member belongs to team1 (ازرق) or team2 (احمر)
      if (team === 'ازرق') {
        countTeam1++; // Increment team1 count
        if (pointsQuestion > maxPointsQuestionTeam1) {
          maxPointsQuestionTeam1 = pointsQuestion;
          topMemberTeam1 = { nickname, profilePictureUrl };
        }
      } else if (team === 'احمر') {
        countTeam2++; // Increment team2 count
        if (pointsQuestion > maxPointsQuestionTeam2) {
          maxPointsQuestionTeam2 = pointsQuestion;
          topMemberTeam2 = { nickname, profilePictureUrl };
        }
      }
  
      // Safely check if 'questions' exists and is an array
      const currentAnswer = questions.find(q => q.currentQuestion === currentQuestion)?.answer;
  
      // Categorize based on followRole and answer
      if (followRole === 1 || followRole === 2) {
        if (currentAnswer === 'true') {
          teamCorrectFollowers.push(member);
        } else if (currentAnswer === 'false') {
          teamWrongFollowers.push(member);
        }
      } else {
        teamNonFollowers.push(member);
      }
    });
  
    setCorrectFollowers(teamCorrectFollowers);
    setWrongFollowers(teamWrongFollowers);
    setNonFollowers(teamNonFollowers);
    setTopMemberTeam1(topMemberTeam1);
    setTopMemberTeam2(topMemberTeam2);
    setTeam1Count(countTeam1); // Update team1 count
    setTeam2Count(countTeam2); // Update team2 count
  }, [currentQuestion]);
  

  const handleNext = () => {
    if (currentQuestion < totalQuestions) {
      navigate(
        `/${lng}/${userId}/teamschallenge/teamsquestion?hashtag=${hashtag}&totalQuestions=${totalQuestions}&timer=${timer}&currentQuestion=${currentQuestion + 1}&level=${level}`
      );
    } else {
      navigate(`/${lng}/${userId}/teamschallenge/teamsleaderboard?totalQuestions=${totalQuestions}`);
    }
  };

  const renderComments = (followers) => {
    const filteredFollowers = followers.filter(follower => 
      (activeTeam === 'team1' && follower.team === 'ازرق') || 
      (activeTeam === 'team2' && follower.team === 'احمر')
    );
  
    return (
      <div className="tab-content mt-2 custom-scrollbar slow-motion" style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <div className="container mb-2">
          <div className="row">
            {filteredFollowers.map((follower, index) => (
              <div 
                key={follower.uniqueId || `${follower.nickname}-${follower.team}-${index}`} 
                className="col-4 col-sm-4 col-md-4 px-1"
              >
                <div className="d-grid align-items-center">
                  <img 
                    src={follower.profilePictureUrl} 
                    alt={follower.nickname} 
                    className="rounded-circle mx-auto" 
                    width="50" 
                    height="50" 
                  />
                  <div>
                    <span className="m-0 fontcolorWhite" style={{ fontSize: 'x-small' }}>
                      {follower.nickname}
                    </span>
                    <p className="m-0 fontcolorYellow">{follower.pointsQuestion}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  

  return (
    <div className="container mt-2 mx-auto" style={{ maxWidth: '300px' }}>
      <div className="mb-2"></div>
      <div className="row justify-content-center mb-1 p-2 bgTransparent rounded-3 mx-auto" style={{ maxWidth: '280px' }}>
        <p className="m-0 col-6 col-sm-6 col-md-6 align-self-center fontcolorWhite">
          {t('question')} : {currentQuestion} / {totalQuestions}
        </p>
        <button className="col-6 col-sm-6 col-md-6 fancy button align-self-center" onClick={handleNext} style={{ maxWidth: '100px' }}>
          <p className="fontcolorYellow fw-bold" style={{ position: 'relative', zIndex: '1', lineHeight: 'revert' }}>
            {currentQuestion < totalQuestions ? t('next') : t('leaders')}
          </p>
        </button>
      </div>
      <div className="row justify-content-center mb-1 p-2 bgTransparent rounded-3 mx-auto" style={{ width: '280px' }}>
        <p className="m-0 fontcolorWhite">
          {t('answer')} : <span className="fontcolorYellow">{correctAnswerNum}</span> - {correctAnswer}
        </p>
      </div>
      <div className="mb-3 rounded-3">
        <ul className="p-0 nav nav-tabs justify-content-around">
          <li className="nav-item">
            <button
              className={`p-1 bg-primary bg-opacity-50 text-white nav-link ${activeTeam === 'team1' ? 'active' : ''}`}
              style={{ borderBottomColor: 'var(--bs-nav-tabs-link-active-border-color)', width: '100px' }}
              onClick={() => setActiveTeam('team1')}
            >
              {topMemberTeam1 && (
                <div className="align-items-center p-0">
                  <div className='d-flex align-items-center justify-content-center'>
                  <img src={topMemberTeam1.profilePictureUrl} alt={topMemberTeam1.nickname} className="rounded-circle mx-1" width="30" height="30" />
                  <span>({team1Count})</span>
                  </div>
                  <span className="mx-1" style={{ display: 'block', fontSize: '0.7rem' }}>{topMemberTeam1.nickname}</span>
                </div>
              )}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`p-1 bg-danger bg-opacity-50 text-white nav-link ${activeTeam === 'team2' ? 'active' : ''}`}
              style={{ borderBottomColor: 'var(--bs-nav-tabs-link-active-border-color)', width: '100px' }}
              onClick={() => setActiveTeam('team2')}
            >
              {topMemberTeam2 && (
                <div className="align-items-center p-0">
                  <div className='d-flex align-items-center justify-content-center'>
                  <img src={topMemberTeam2.profilePictureUrl} alt={topMemberTeam2.nickname} className="rounded-circle mx-1" width="30" height="30" />
                  <span>({team2Count})</span>
                  </div>
                  <span className="mx-1" style={{ display: 'block', fontSize: '0.7rem' }}>{topMemberTeam2.nickname}</span>
                </div>
              )}
            </button>
          </li>
        </ul>

        <div className="mb-3 bgTransparent rounded-3">
          <ul className="p-0 nav nav-tabs justify-content-around">
            <li className="nav-item">
              <button
                className={`nav-link ${activeSubTab === 'correctFollowers' ? 'active' : ''}`}
                onClick={() => setActiveSubTab('correctFollowers')}
              >
                {t('correct')}
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeSubTab === 'wrongFollowers' ? 'active' : ''}`}
                onClick={() => setActiveSubTab('wrongFollowers')}
              >
                {t('wrong')}
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeSubTab === 'nonFollowers' ? 'active' : ''}`}
                onClick={() => setActiveSubTab('nonFollowers')}
              >
                {t('not_following')}
              </button>
            </li>
          </ul>

          <div className="tab-content mt-2">
            {activeSubTab === 'correctFollowers' && renderComments(correctFollowers)}
            {activeSubTab === 'wrongFollowers' && renderComments(wrongFollowers)}
            {activeSubTab === 'nonFollowers' && renderComments(nonFollowers)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsResult;
