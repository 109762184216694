import io from 'socket.io-client';

let socket;

export const getSocket = () => {
  if (!socket) {
    socket = io('https://backend-tok.weblab99.com');
  }
  return socket;
};
